<template>
    <div class="home">
        <van-nav-bar
                title="搜索"
                left-text="返回"
                left-arrow
                @click-left="backToHistory"
        />
        <van-search
                v-model="params.keywords"
                placeholder="请输入搜索关键词"
                input-align="center"
                @search="handleSearch"
                show-action
        >
            <template slot="action">
                <div @click="handleSearch">搜索</div>
            </template>
        </van-search>
        <div class="filter-div">
            <van-popover
                    v-model="showFilter"
                    trigger="click"
                    placement="bottom-start"
            >
                    <div class="single-row-params">
                        <span>类型：</span>
                        <div class="tags">
                            <van-tag
                                    :key="item.type"
                                    @click="selectFileType(item.type)"
                                    v-for="item in fileType"
                                    :plain="!params.fileType.includes(item.type)"
                                    round
                                    size="large"
                                    type="primary">{{ item.name }}</van-tag>
                        </div>
                    </div>
                    <div class="single-row-params">
                        <span>范围：</span>
                        <div class="tags">
                            <van-tag @click="selectScope('all')" :plain="params.scope !== 'all'" round size="large"
                                     type="primary">公司云盘
                            </van-tag>
                            <van-tag @click="selectScope('all')" :plain="params.scope !== 'current'" round size="large"
                                     type="primary" v-show="spaceId">当前文件夹
                            </van-tag>
                        </div>
                    </div>
                    <div class="multi-row-params">
                        <span>标签：</span>
                        <div class="tags">
                            <van-tag
                                    @click="selectTag(tag.tagId)"
                                    :key="tag.tagId"
                                    v-for="tag in myTagList"
                                    :type="tag.tagColor"
                                    :plain="!params.tagIds.includes(tag.tagId)"
                                    size="large">{{ tag.tagName }}</van-tag>
                        </div>
                    </div>
                <template slot="reference">
                    <a>筛选<i class="iconfont" :class="[ showFilter ? 'icon-shouqi' : 'icon-zhankai' ]"></i></a>
                </template>
            </van-popover>
        </div>
        <van-pull-refresh v-model="fileRefreshing" @refresh="reLoadFile">
            <van-list
                    v-model="fileLoading"
                    :finished="fileFinished"
                    finished-text=""
                    @load="handleSearch"
                    class="content-fill"
            >
                <FileCard
                        v-for="item in fileList"
                        :key="'div'+ item.id"
                        slot="default"
                        @refresh="reLoadFile"
                        :file-info="item"
                        :from-search="true"
                />
                <van-empty description="无搜索结果" v-if="isListEmpty"/>
            </van-list>
        </van-pull-refresh>
    </div>
</template>

<script>
import Vue from 'vue';
import {
    Divider,
    Cell,
    List,
    PullRefresh,
    Col,
    Row,
    Icon,
    ActionSheet,
    Search,
    NavBar,
    Tag,
    Notify,
    Popover
} from 'vant';

import {FILE_TYPE, formatTime} from "@/utils/commonFunction";
import {getUserSpaceList, getUserTagList, searchFile} from "@/api/manage";
import FileCard from "@/views/components/FileCard.vue";

Vue.use(List);
Vue.use(Cell);
Vue.use(Divider);
Vue.use(PullRefresh);
Vue.use(Col);
Vue.use(Row);
Vue.use(Icon);
Vue.use(ActionSheet);
Vue.use(Search);
Vue.use(NavBar);
Vue.use(Tag);
Vue.use(Notify);
Vue.use(Popover);

export default {
    name: 'Search',
    components: {FileCard},
    watch: {
    },
    methods: {
        reLoadFile() {
            this.fileRefreshing = true
            this.handleSearch()
        },
        async handleSearch() {
            this.fileList = []
            // 查询全空间的文件时必须精确条件, 填写关键词或选择标签
            if (this.params.scope === 'all') {
                if (!this.params.keywords.trim() && this.params.tagIds.length === 0) {
                    Notify({ type: 'warning', message: '查询全网盘文件时必须精确条件, 请填写关键词或选择标签!' });
                    return
                }
            }
            const params = {
                keyword: this.params.keywords?.trim(),
                unionId: this.dingUserInfo.unionid,
                tagIds: this.params.tagIds
            }
            if (this.params.scope === 'current') {
                params.limitSpaceId = this.spaceId
                if (this.parentId) {
                    params.limitFolderId = this.parentId
                }
            }
            params.spaceIds = this.spaceList
            if (this.params.fileType.length > 0) {
              let extensions = []
              this.params.fileType.forEach(type => {
                  extensions = extensions.concat(FILE_TYPE[type])
              })
                console.log(extensions)
              params.extensions = extensions
            }
            this.fileLoading = true
            const { data } = await searchFile(params)
            this.fileList = data
            this.fileLoading = false
            this.fileFinished = true
            this.fileRefreshing = false
        },
        async getSpaceList() {
            const {code, data} = await getUserSpaceList(this.dingUserInfo.unionid)
            if (code === 200) {
                this.spaceList = data.map(space => { return space.spaceId })
            }
        },
        async userTagList () {
            const {data} = await getUserTagList(this.dingUserInfo.unionid)
            this.myTagList = data
        },
        backToHistory() {
            this.$router.back()
        },
        selectScope (scope) {
            this.params.scope = scope
            this.handleSearch()
        },
        selectFileType (type) {
            if (!this.params.fileType.includes(type)) {
                this.params.fileType.push(type)
            } else {
                this.params.fileType.splice(this.params.fileType.indexOf(type), 1)
            }
            this.handleSearch()
        },
        selectTag (tagId) {
            if (!this.params.tagIds.includes(tagId)) {
                this.params.tagIds.push(tagId)
            } else {
                this.params.tagIds.splice(this.params.tagIds.indexOf(tagId), 1)
            }
            this.handleSearch()
        }
    },
    computed: {
        dingUserInfo() {
            return JSON.parse(sessionStorage.getItem('dingUserInfo'))
        },
        formatTime() {
            return (time) => {
                return formatTime(time)
            }
        },
        folderIcon() {
            return (name) => {
                if (name === '全员文件夹') {
                    return '#icon-wenjianjia'
                }
                return '#icon-gongxiangwenjianjia'
            }
        }
    },
    async mounted() {
        this.spaceId = this.$route.query.spaceId
        this.queryTag = this.$route.query.queryTag
        this.parentId = this.$route.query.parentId || 0
        if (this.spaceId) {
            this.params.scope = 'current'
        }
        await this.getSpaceList()
        await this.userTagList()
        if (this.queryTag) {
            this.params.tagIds = [ this.queryTag ]
            this.handleSearch()
        }
    },
    data() {
        return {
            spaceId: 0,
            parentId: 0,
            showFilter: false,
            fileType: [
                {name: '文档', type: 'doc'},
                {name: '图片', type: 'image'},
                {name: '音频', type: 'audio'},
                {name: '视频', type: 'video'},
                {name: '压缩包', type: 'pack'}
            ],
            params: {
                keywords: '',
                scope: 'all',
                fileType: [],
                tagIds: []
            },
            myTagList: [],
            spaceList: [],
            fileLoading: false,
            fileRefreshing: false,
            fileFinished: true,
            fileList: []
        };
    },
}
</script>

<style scoped lang="less">
.content {
  padding: 16px 16px 160px;
}

.content-fill {
  overflow: auto;
  height: calc(100vh - 180px);
}
@media screen and ( max-width: 500px ) {
  .content-fill {
    height: calc(100vh - 170px);
  }
}
.file-card {
  height: 52px;
  display: flex;
  align-items: center;
  padding: 4px 24px;

  &:hover {
    background-color: #EFEFEF;
  }
}

.file-card-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.file-card-title-desc {
  font-size: 12px;
  color: #8f99a8;
  font-weight: 500;
}

.file-card-title span {
  margin-left: 8px;
}

.file-card-opr {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 3em;
  height: 3em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.filter-div {
  display: flex;
  justify-content: flex-start;
  //width: 100%;
  padding: 0 18px;
  font-size: 16px;
  align-items: center;
  cursor: pointer;

  div {
    display: inline-flex;
    align-items: center;
  }
}

.single-row-params {
  display: flex;
  justify-content: flex-start;
  //width: 100%;
  padding: 8px 18px;
  line-height: 28px;
}

.multi-row-params {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 8px 18px;
  line-height: 28px;
}

.tags {
  .van-tag {
    margin-right: 8px;
  }
}
</style>