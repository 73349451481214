<template>
    <div id="app">
        <div v-if="isDingEnv">
            <van-tabbar v-model="active" route>
                <van-tabbar-item icon="home-o" replace to="/">
                    <span>云空间</span>
                    <template slot="icon">
                        <i class="iconfont icon-yunpan" style="font-size: 20px;"></i>
                    </template>
                </van-tabbar-item>
                <van-tabbar-item icon="user-circle-o" replace to="/my">我的</van-tabbar-item>
            </van-tabbar>
        </div>
        <router-view v-if="isRouterAlive"/>
    </div>
</template>

<script>
import Vue from 'vue';
import {Tabbar, TabbarItem} from 'vant';
import {checkIfNoLogin} from "@/utils/commonFunction";

Vue.use(Tabbar);
Vue.use(TabbarItem);

export default {
    provide() {
        return {
            reload: this.reload
        }
    },
    data() {
        return {
            active: 0,
            isRouterAlive: true,
            isDingEnv: false
        };
    },
    beforeCreate() {
        window.addEventListener('setItem', ()=> {
            this.isDingEnv = sessionStorage.getItem('isDingEnv')
        })
        checkIfNoLogin()
    },
    methods: {
        reload() {
            this.isRouterAlive = false;
            this.$nextTick(function () {
                this.isRouterAlive = true;
            })
        },
        login() {
            //   console.log(corpId)
            //   dd.ready(function() {
            //     dd.runtime.permission.requestAuthCode({
            //       corpId: corpId,
            //       onSuccess: async function (result) {
            //         console.log(result)
            //         const { data } = await getUserInfoByCode(result.code)
            //         const res = JSON.parse(data)
            //         if (res.errcode === 0) {
            //           console.log(JSON.parse(JSON.stringify(res.result)))
            //           sessionStorage.setItem('dingUserInfo', JSON.stringify(res.result))
            //         } else {
            //           Notify(res.errmsg)
            //         }
            //       },
            //       onFail: function (err) {
            //         console.log(err)
            //       }
            //     })
            //   });
        }
    }
}
</script>


<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}
#nav {
    padding: 30px;
}

#nav a {
    font-weight: bold;
    color: #2c3e50;
}

#nav a.router-link-exact-active {
    color: #42b983;
}

.icon {
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
}
.van-tabbar {
    padding-bottom: 0 !important;
}
</style>
