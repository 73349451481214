<template>
  <div>
      <van-nav-bar
              title="我的标签"
              left-text="返回"
              :right-text="rightText"
              left-arrow
              @click-left="backToMy"
              @click-right="isEditing = !isEditing"
      />
      <div class="container">
          <van-tag plain type="success" size="large" @click="createNewTag">
              <i class="iconfont icon-dianji"></i>新增标签
          </van-tag>
          <div class="tags">
              <van-tag @click="handleTagClick(tag)"
                       :key="tag.tagId"
                       v-for="tag in myTagList"
                       :type="tag.tagColor"
                       :closeable="isEditing"
                       @close="removeTag(tag)"
                       size="large">{{ tag.tagName }}
              </van-tag>
          </div>
      </div>
      <van-popup v-model="showAddTag" round position="bottom">
          <div :style="{ padding: '16px' }">
              <van-row>
                  <van-field ref="tagName" required v-model="tagInfo.tagName" :border="true"
                             placeholder="请输入标签名" maxlength="10" :show-word-limit="true"/>
              </van-row>
              <van-row style="margin: 16px 0;">
                  <van-radio-group v-model="tagInfo.tagColor" direction="horizontal">
                      <van-radio name="primary">
                          <van-tag type="primary" size="medium">&emsp;</van-tag>
                      </van-radio>
                      <van-radio name="success">
                          <van-tag type="success" size="medium">&emsp;</van-tag>
                      </van-radio>
                      <van-radio name="danger">
                          <van-tag type="danger" size="medium">&emsp;</van-tag>
                      </van-radio>
                      <van-radio name="warning">
                          <van-tag type="warning" size="medium">&emsp;</van-tag>
                      </van-radio>
                  </van-radio-group>
              </van-row>
              <van-button size="small" @click.stop="handleSaveTag" style="width: 100%;" round type="info">
                  保存
              </van-button>
          </div>
      </van-popup>
  </div>
</template>

<script>
import Vue from "vue";
import {
    Cell,
    Col,
    Divider,
    Icon,
    List,
    Row,
    NavBar,
    Toast,
    Dialog
} from "vant";
import {createTag, deleteTag, getUserTagList, updateTag} from "@/api/manage";
Vue.use(List);
Vue.use(Cell);
Vue.use(Col);
Vue.use(Row);
Vue.use(Icon);
Vue.use(Divider);
Vue.use(NavBar)
Vue.use(Dialog)
export default {
    name: "MyTags",
    data () {
        return {
            myTagList: [],
            loading: false,
            finished: true,
            showAddTag: false,
            tagInfo: {
                tagName: '',
                tagColor: 'primary'
            },
            isEditing: false,
            showEdit: false
        }
    },
    computed: {
        dingUserInfo() {
            return JSON.parse(sessionStorage.getItem('dingUserInfo'))
        },
        rightText () {
            return this.isEditing ? '完成' : '编辑'
        }
    },
    mounted () {
        this.getUserTagList()
    },
    methods: {
        removeTag (tag) {
            const this_ = this
            Dialog.confirm({
                title: '确认删除',
                message: `当前标签已标记 ${ tag.usedCount } 个文件, 是否删除?`,
            }).then(() => {
                deleteTag(tag.tagId).then(res => {
                    if (res.code === 200) {
                        Toast.success('删除成功!')
                        this_.getUserTagList()
                    } else {
                        Toast.fail('删除失败!')
                    }
                })
            }).catch(() => {
                // on cancel
            });
        },
        createNewTag() {
            this.tagInfo = {
                tagName: '',
                tagColor: 'primary'
            }
            this.showAddTag = true
        },
        handleSaveTag() {
            if (!this.tagInfo.tagName || !this.tagInfo.tagName.trim()) {
                this.$refs.tagName.focus()
                Toast.fail('请输入标签名!')
                return
            }
            console.log(this.tagInfo)
            this.tagInfo.unionId = this.dingUserInfo.unionid
            if (!this.tagInfo.tagId) {
                createTag(this.tagInfo).then(res => {
                    if (res.code === 200) {
                        Toast.success('创建成功!')
                        this.getUserTagList()
                        this.showAddTag = false
                    } else {
                        Toast.fail('创建失败!')
                    }
                })
            } else {
                updateTag(this.tagInfo).then(res => {
                    if (res.code === 200) {
                        Toast.success('保存成功!')
                        this.getUserTagList()
                        this.showAddTag = false
                    } else {
                        Toast.fail('保存失败!')
                    }
                })
            }
        },
        handleTagClick (tag) {
            console.log(tag)
            if (this.isEditing) {
                this.tagInfo = JSON.parse(JSON.stringify(tag))
                this.showAddTag = true
            } else {
                if (tag.usedCount) {
                    this.$router.push({
                        path: "/search",
                        name: "Search",
                        query: {
                            queryTag: tag.tagId
                        }
                    })
                } else {
                    Toast.fail('该标签未标记文件!')
                }
            }
        },
        backToMy () {
            this.$router.push({
                path: "/my",
                name: "My",
            })
        },
        getUserTagList () {
            getUserTagList(this.dingUserInfo.unionid).then(res => {
                if (res.code === 200) {
                    this.myTagList = res.data
                }
            })
        }
    }
}
</script>

<style scoped lang="less">
.container {
  padding: 18px 24px;
  .tags {
    display: flex;
    flex-wrap: wrap;
  }
}
.van-tag {
  margin-right: 8px;
  margin-top: 12px;
  line-height: 24px;
}
::v-deep .van-field {
  .van-cell__value {
    display: inline-flex;
    justify-content: space-between;
  }
}
</style>