import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Search from '../views/Search.vue'
import File from "@/views/File.vue";
import My from "@/views/My.vue";
import Refresh from "@/views/Refresh.vue";
import SubscribeList from "@/views/SubscribeList.vue";
import MySubscribe from "@/views/MySubscribe.vue";
import MyTags from "@/views/MyTags.vue";
import PanDownload from "@/views/PanDownload.vue";
import MyShare from "@/views/MyShare.vue";
import ShareInfo from "@/views/ShareInfo.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/search',
    name: 'Search',
    component: Search
  },
  {
    path: '/file',
    name: 'File',
    component: File
  },
  {
    path: '/refresh',
    name: 'Refresh',
    component: Refresh
  },
  {
    path: '/my',
    name: 'My',
    component: My
  },
  {
    path: '/subscribes',
    name: 'Subscribes',
    component: SubscribeList
  },
  {
    path: '/mySubscribe',
    name: 'MySubscribe',
    component: MySubscribe
  },
  {
    path: '/myTags',
    name: 'MyTags',
    component: MyTags
  },
  {
    path: '/myShare',
    name: 'MyShare',
    component: MyShare
  },
  {
    path: '/shareInfo',
    name: 'ShareInfo',
    component: ShareInfo
  },
  {
    path: '/download/:shareKey',
    name: 'PanDownload',
    component: PanDownload
  }
]

const original = VueRouter.prototype.replace

VueRouter.prototype.replace = function replace(location) {
  return original.call(this, location).catch(err => err)
}

const router = new VueRouter({
  routes,
  mode: 'hash'
})

export default router
