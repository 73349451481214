<template>
  <div>
      <van-nav-bar
              title="我的订阅"
              left-text="返回"
              left-arrow
              @click-left="backToMy"
      />
      <div class="my-subscribe">
          <van-pull-refresh v-model="fileRefreshing" @refresh="reLoadFile">
              <van-list
                      v-model="fileLoading"
                      :finished="fileFinished"
                      finished-text=""
                      @load="getUserSubscribeList"
                      class="content-fill"
              >
                  <FileCard
                          v-for="item in subscribeList"
                          :key="'div'+ item.id"
                          slot="default"
                          @refresh="reLoadFile"
                          :file-info="item"
                          :from-search="true"
                  />
                  <van-empty description="请到网盘中订阅更新~" v-if="subscribeList.length === 0"/>
              </van-list>
          </van-pull-refresh>
      </div>
  </div>
</template>

<script>
import Vue from "vue";
import {
    Cell,
    Col,
    Divider,
    Icon,
    List,
    Row,
    NavBar
} from "vant";
import { userSubscribe } from "@/api/manage";
import FileCard from "@/views/components/FileCard.vue";
Vue.use(List);
Vue.use(Cell);
Vue.use(Col);
Vue.use(Row);
Vue.use(Icon);
Vue.use(Divider);
Vue.use(NavBar)
export default {
    name: "MySubscribe",
    components: {FileCard},
    data () {
        return {
            subscribeList: [],
            fileLoading: false,
            fileRefreshing: false,
            fileFinished: true,
        }
    },
    computed: {
        dingUserInfo() {
            return JSON.parse(sessionStorage.getItem('dingUserInfo'))
        }
    },
    mounted () {
        this.getUserSubscribeList()
    },
    methods: {
        reLoadFile() {
            this.fileRefreshing = true
            this.getUserSubscribeList()
        },
        backToMy () {
            this.$router.push({
                path: "/my",
                name: "My",
            })
        },
        getUserSubscribeList () {
            userSubscribe(this.dingUserInfo.unionid).then(res => {
                if (res.code === 200) {
                    this.subscribeList = res.data
                }
                this.fileLoading = false
                this.fileRefreshing = false
                this.fileFinished = true
            })
        }
    }
}
</script>

<style scoped lang="less">
.content-fill {
    height: calc(100vh - 100px);
}
@media screen and ( max-width: 500px ) {
    .content-fill {
        height: calc(100vh - 140px);
    }
}
.van-pull-refresh {
    overflow: auto;
}
</style>