<template>
  <div>
      <van-nav-bar
              title="我的分享"
              left-text="返回"
              left-arrow
              @click-left="backToMy"
              @click-right="selectAll"
              :right-text="rightText"
      />
      <van-tabs v-model="type" color="#1989fa" @change="changeTab" style="margin-bottom: 4px;">
          <van-tab title="全部" name="all"></van-tab>
          <van-tab title="分享有效" name="sharing"></van-tab>
          <van-tab title="分享过期" name="expired"></van-tab>
      </van-tabs>
      <div class="main-content">
          <van-checkbox-group ref="shareCheckbox" v-model="selectedShares" @change="checkShare">
            <template v-for="item in shareList">
                <div class="file-card" :class="[ selectedShares.includes(item.key) ? 'selected' : '' ]" :key="'div'+ item.key">
                    <van-row type="flex" justify="center" style="width: 100%">
                        <van-col span="4" style="display: flex;justify-content: center;">
                            <div class="file-icon">
                                <svg class="icon" aria-hidden="true">
                                    <use :xlink:href="folderIcon(item.extension, item.type)"></use>
                                </svg>
                                <van-badge class="subscribe" v-if="item.downloadNum" :content="item.downloadNum"/>
                            </div>
                        </van-col>
                        <van-col @click="toShareInfo(item.key)" span="16" class="file-card-title">
                            <span :key="item.id" :class="[ item.expired ? 'expired': '' ]">{{ item.name }}</span>
                            <span class="file-card-title-desc">
                              <template v-if="item.createTime">
                                  <span style="float: left;">{{ formatTime(item.createTime) }} &emsp;</span>
                              </template>
                              <template v-if="item.expireTime">
                                  <span style="float: right;" :class="[ calcExpiredTime(item.expireTime) <=3 ? 'less-than-three' : '' ]" v-show="!item.expired">{{ expiredTime(item.expireTime) }} &emsp;</span>
                                  <span style="float: right;" class="expired" v-show="item.expired">已失效 &emsp;</span>
                              </template>
                              <template v-if="!item.expireTime">
                                  <span style="float: right;">永久有效 &emsp;</span>
                              </template>
                           </span>
                            <span class="file-card-title-desc" v-if="item.remark">
                                {{ item.remark }}
                            </span>
                        </van-col>
                        <van-col class="file-card-opr" span="4">
<!--                            <van-icon v-if="item.type !== 'backToTop'" name="ellipsis"/>-->
                            <van-checkbox :name="item.key"></van-checkbox>
                        </van-col>
                    </van-row>
                </div>
            </template>
          </van-checkbox-group>
      </div>
      <van-action-sheet style="background-color: #F6F7F9;" v-model="showOpr" title="" :closeable="false" :overlay="false" :close-on-popstate="true" :close-on-click-overlay="false">
          <div class="share-opr">
              <div class="opr-btn" @click="cancelShare">
                  <div class="opr-icon"><i class="iconfont icon-quxiao"></i></div>
                  <div class="opr-desc">取消分享</div>
              </div>
              <div class="opr-btn" v-show="selectedShares.length === 1 && !selectedOneShare.expired" @click="copyUrl">
                  <div class="opr-icon"><i class="iconfont icon-chaolianjie"></i></div>
                  <div class="opr-desc">复制链接</div>
              </div>
              <div class="opr-btn" v-show="selectedShares.length === 1 && selectedOneShare.expired">
                  <div class="opr-icon"><i class="iconfont icon-share"></i></div>
                  <div class="opr-desc">再次分享</div>
              </div>
          </div>
      </van-action-sheet>
  </div>
</template>
<script>
import {Dialog, Tab, Tabs, Toast} from "vant";
import Vue from "vue";
import {cancelShare, getMyShareList} from "@/api/manage";
import {formatTime, getIconWithExtension} from "@/utils/commonFunction";
import moment from "moment/moment";
import * as dd from "dingtalk-jsapi";
Vue.use(Tabs)
Vue.use(Tab)
export default {
    name: "MyShare",
    data() {
        return {
            type: 'all',
            rightText: '全选',
            shareList: [],
            selectedShares: [],
            selectedOneShare: {},
            showOpr: false
        }
    },
    computed: {
        dingUserInfo() {
            return JSON.parse(sessionStorage.getItem('dingUserInfo'))
        },
        folderIcon() {
            return (extension, type) => {
                if (type === 'FOLDER') {
                  return '#icon-folder'
                }
                return getIconWithExtension(extension)
            }
        },
        formatTime() {
            return (time) => {
                if (time) {
                    return formatTime(time.replace('CST', 'GMT+8'))
                }
                return ''
            }
        },
        expiredTime() {
            return (time) => {
                if (!time) {
                    return '永久有效'
                }
                const timeParse = moment(time).diff(moment(), 'days')
                if (timeParse === 0) {
                    return '即将失效'
                } else if (timeParse > 0) {
                    return `${timeParse} 天后过期`
                } else if (timeParse < -180) {
                    return '过期超过 180 天'
                } else {
                    return `已过期 ${Math.abs(timeParse)} 天`
                }
            }
        },
        calcExpiredTime() {
            return (time) => {
                if (!time) {
                    return 0
                }
                const timeParse = moment(time).diff(moment(), 'days')
                return timeParse
            }
        }
    },
    mounted () {
        this.getShareList()
    },
    methods: {
        toShareInfo (shareKey) {
            this.$router.push({
                path: "/shareInfo",
                name: "ShareInfo",
                query: {
                    shareKey: shareKey
                }
            })
        },
        copyUrl () {
            const item = this.selectedOneShare
            const baseUrl = `${window.location.origin}/#/download`
            // 请求key
            const key = item.key
            if (key) {
                const this_ = this
                let url = `${baseUrl}/${key}`
                if (item.needCode) {
                    Dialog.confirm({
                        title: '是否开启『自动填充提取码』',
                        message: '开启后访问者无需输入提取码，可直接查看分享文件',
                    }).then(() => {
                        url += `?pwd=${item.code}`
                        this_.copyContent (url)
                    }).catch(() => {
                        this_.copyContent (url)
                    })
                } else {
                    this_.copyContent (url)
                }
            }
            this.selectedOneShare = {}
            this.selectedShares = []
            this.showOpr = false
        },
        copyContent (url) {
            if (dd.env.platform === 'pc' || dd.env.platform === 'notInDingTalk') {
                this.$copyText(url).then(
                    () => {
                        Toast.success('复制成功');
                    },
                    (e) => {
                        Toast.fail('复制失败');
                        console.error(e)
                    })
            } else {
                dd.ready(function () {
                    dd.biz.clipboardData.setData({
                        text: url, //要复制粘贴板的内容
                        onSuccess: function () {
                            Toast.success('复制成功');
                        },
                        onFail: function (err) {
                            console.error(JSON.stringify(err))
                            Toast.fail('复制失败');
                        }
                    })
                });
            }
        },
        cancelShare () {
            const this_ = this
            Dialog.confirm({
                title: '',
                message: '取消分享后，该链接将从列表中清除，且无法被继续访问，是否继续？',
            }).then(() => {
                cancelShare(this_.dingUserInfo.unionid, this_.selectedShares.join(',')).then(res => {
                    if (res.code === 200) {
                        Toast.success('取消成功!')
                        this_.selectedShares = []
                        this_.getShareList()
                    } else {
                        Toast.fail('取消失败!')
                    }
                })
            }).catch(() => {
                // on cancel
            })
        },
        checkShare (e) {
            if (e.length === 1) {
                // 查看这个分享是否已经预逾期
                const item = this.shareList.filter(t => { return t.key === e[0] })
                if (item) {
                    this.selectedOneShare = item[0]
                }
            }
            if (e.length > 0) {
                this.showOpr = true
            } else {
                this.showOpr = false
            }
        },
        changeTab (n) {
            this.type = n
            this.getShareList()
        },
        backToMy () {
            this.$router.push({
                path: "/my",
                name: "My",
            })
        },
        selectAll () {
            console.log('+++++++')
            this.$refs.shareCheckbox.toggleAll()
            if (this.selectedShares.length === this.shareList.length) {
                this.rightText = '取消全选'
            }  else {
                this.rightText = '全选'
            }
        },
        async getShareList () {
            const { code, data } = await getMyShareList(this.dingUserInfo.unionid, this.type)
            if (code === 200) {
                this.shareList = data
            }
        }
    }
}
</script>
<style scoped lang="less">
.file-card {
  height: 52px;
  display: flex;
  align-items: center;
  padding: 8px 8px;
  margin: 4px 0;
  &:hover {
    background-color: #EFEFEF;
  }
}
.selected {
  background-color: #EFEFEF;
}
.file-card-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.file-card-title-desc {
  font-size: 12px;
  color: #8f99a8;
  font-weight: 500;
}

.file-card-title span {
  display: -webkit-box;
  overflow: hidden;
  white-space: normal !important;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: 1;
  /* autoprefixer: off*/
  -webkit-box-orient: vertical;
  /* autoprefixer: on*/
  text-align: left;
  max-width: -webkit-fill-available;
}

.file-card-opr {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 3em;
  height: 3em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.el-breadcrumb {
  line-height: 2;
}

.file-icon {
  position: relative;
  width: fit-content;

  .subscribe {
    width: 1.3em;
    position: absolute;
    bottom: 4px;
    right: 0px;
  }
}

.van-tag--large {
  line-height: 24px;
  margin-left: 8px;
  margin-bottom: 4px;
}

::v-deep .van-field {
  .van-cell__value {
    display: inline-flex;
    justify-content: space-between;
  }
}
.expired {
  color: #ABB3BF;
}
.less-than-three {
  color: orangered;
}
.main-content {
  height: calc(100vh - 150px);
  overflow: auto;
}
.share-opr {
  padding: 12px;
  display: flex;
  justify-content: space-evenly;
  .opr-btn {
    .opr-icon {}
    .opr-desc {
      font-size: 12px;
    }
  }
  & :hover {
    color: #409eff;
  }
}
</style>