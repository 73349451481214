<template>
    <div class="my">
        <div>
            <van-row>
                <van-image
                        fit="cover"
                        round
                        width="6rem"
                        height="6rem"
                        :src="avatar"
                />
            </van-row>
            <van-row><span style="font-weight: 600;">{{ userInfo.name }}</span></van-row>
        </div>
        <van-divider />
        <div class="sub-list" @click="showMoreSubs">
            <van-row type="flex">
                <van-col span="6" style="text-align: left;">
                    最新消息：
                </van-col>
            </van-row>
            <template v-if="noticeNew.noticeId">
                <van-row type="flex" justify="top-start" style="margin-top: 18px;">
                    <van-col span="3">
                        <svg class="icon" aria-hidden="true">
                            <use :xlink:href="folderIcon(noticeFileInfo.type, noticeFileInfo.extension)"></use>
                        </svg>
                    </van-col>
                    <van-col span="21" style="font-size: 14px;text-align: left;color: #909399;">
                        <span class="message-ellipsis">{{ noticeNew.noticeMsg }}</span>
                    </van-col>
                </van-row>
                <van-row type="flex" justify="end" style="margin-top: 12px;color: #909399;font-size: 14px;">
                    {{ formatTime(noticeNew.createTime) }}&emsp;
                    操作人：{{ noticeNew.oprUserName }}
                </van-row>
            </template>
            <template v-if="!noticeNew.noticeId">
                <van-empty image-size="88px" description="暂无订阅消息" />
            </template>
        </div>
        <van-divider />
        <div>
            <van-cell title="我的订阅" is-link to="mySubscribe" class="link-cell">
                <template slot="icon">
                    <van-icon class="link-icon" name="smile-comment"></van-icon>
                </template>
            </van-cell>
            <van-cell title="我的标签" is-link to="myTags" class="link-cell">
                <template slot="icon">
                    <van-icon class="link-icon" name="bookmark"></van-icon>
                </template>
            </van-cell>
            <van-cell title="我的分享" is-link to="myShare" class="link-cell">
                <template slot="icon">
                    <van-icon class="link-icon" name="share"></van-icon>
                </template>
            </van-cell>
            <van-cell title="数据问题修复" is-link class="link-cell" @click="repairDataProblem">
                <template slot="icon">
                    <van-icon class="link-icon" name="question"></van-icon>
                </template>
            </van-cell>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import {currentUserInfo, getDentryInfo, getUserSpaceList, noticeNow, repairUserData} from "@/api/manage";

import {Image, Row, Divider, NoticeBar, Swipe, SwipeItem, List, Empty, Dialog, Toast} from "vant";
import {formatTime, getIconWithExtension} from "@/utils/commonFunction";
Vue.use(Image)
Vue.use(Row)
Vue.use(Divider)
Vue.use(NoticeBar)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(List)
Vue.use(Empty)

export default {
    name: "My",
    data() {
        return {
            userInfo: {
            },
            loadingSub: false,
            finishedSub: true,
            noticeNew: {},
            noticeFileInfo: {}
        }
    },
    mounted () {
        this.setUserSpaceCache()
        this.getCurrentUserInfo()
        this.getUserLatestNotice()
    },
    computed: {
        dingUserInfo() {
            return JSON.parse(sessionStorage.getItem('dingUserInfo'))
        },
        folderIcon() {
            return (type, extension) => {
                if (type === 'FOLDER') {
                    return '#icon-folder'
                } else if (type === 'backToTop') {
                    return '#icon-folder-top'
                }
                return getIconWithExtension(extension)
            }
        },
        formatTime() {
            return (time) => {
                if (time) {
                    return formatTime(time.replace('CST', 'GMT+8'))
                }
                return ''
            }
        },
        avatar () {
            return this.userInfo.avatar || './avatar.webp'
        }
    },
    methods: {
        repairDataProblem () {
            Dialog.confirm({
                title: '数据问题修复',
                message: '若您发现您的当前应用中的数据与云盘中的数据不一致, 可使用此功能进行修复!',
            }).then(() => {
                repairUserData(this.dingUserInfo.unionid).then(res => {
                    if (res.code == 200) {
                        Toast.success('修复任务执行成功, 请稍后重启应用查看同步结果!')
                    } else {
                        Toast.fail('修复任务执行失败, 请联系管理员!')
                    }
                })
            })
        },
        showMoreSubs () {
            this.$router.push({
                path: "/subscribes",
                name: "Subscribes",
            })
        },
        async getCurrentUserInfo() {
            const {data} = await currentUserInfo(this.dingUserInfo.unionid)
            if (data) {
                this.userInfo = data
            }
        },
        async getUserLatestNotice() {
            const {data} = await noticeNow(this.dingUserInfo.unionid)
            if (data) {
                this.noticeNew = data
                getDentryInfo(data.entryId, this.dingUserInfo.unionid).then(res => {
                    if (res.code === 200 && res.data) {
                        this.noticeFileInfo = res.data
                    }
                })
            }
        },
        async setUserSpaceCache() {
            const {data} = await getUserSpaceList(this.dingUserInfo.unionid)
            if (data) {
                const userSpaces = data.map(space => { return space.spaceId })
                sessionStorage.setItem('userSpaces', userSpaces)
            }
        }
    }
}
</script>

<style scoped lang="less">
.my {
  padding: 24px 0;
  height: calc(100vh - 100px);
}
.van-divider {
  border-color: #EFEFEF;
  margin: 8px 0;
}
.van-divider::after, .van-divider::before {
  border-width: 24px 0 0;
}
.notice-swipe {
  height: 40px;
  line-height: 40px;
}
.sub-list {
    min-height: 140px;
    border: 1px solid #EFEFEF;
    margin: 8px;
    padding: 8px;
    border-radius: 8px;
    background-color: #f6f7f9;
    cursor: pointer;
}
.icon {
    width: 2.5em;
    height: 2.5em;
}
.message-ellipsis {
    display: -webkit-box;
    overflow: hidden;
    white-space: normal !important;
    text-overflow: ellipsis;
    word-wrap: break-word;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-align: left;
    max-width: -webkit-fill-available;
}
.link-cell {
    align-items: center;
}
.link-icon {
    color: #606266;
    font-size: 24px;
}
</style>
