<template>
  <div v-if="loading === false">
    <div v-if="shareInfo && shareInfo.simpleMode">
      <!-- 放置下载列表 -->
      <div>
        <template v-if="!loading">
          <fold-file-list
              v-if="shareInfo"
              :loading="loading"
              :share-key="shareKey"
              :share-info="shareInfo"
          ></fold-file-list>
          <template v-if="shareInfo && isExpired">
            <span style="font-weight: 600;">文件已过期~</span>
          </template>
          <template v-if="!shareInfo">
            <span style="font-weight: 600;">文件链接不存在~</span>
          </template>
        </template>
        <van-loading type="spinner" v-if="loading"/>
      </div>
    </div>
    <div style="background-color: #f6f7f9;height: calc(100vh)" v-else>
      <van-row justify="start" style="background-color: #EBEEF5;">
        <van-col span="6">
          <van-image
              width="164"
              height="36"
              :src="shareInfo.englishMode ? '/logo_en.png' : '/logo.png'"
              style="margin: 12px;cursor: pointer;"
              @click="toHome"
          />
        </van-col>
      </van-row>
      <!-- 放置下载列表 -->
      <div class="pan-download">
        <div>
          <template v-if="!loading">
            <van-row type="flex" justify="center" style="width: 100%">
              <div class="file-icon">
                <svg class="icon" aria-hidden="true">
                  <use :xlink:href="folderIcon(shareInfo.extension, shareInfo.type)"></use>
                </svg>
              </div>
            </van-row>
            <van-row type="flex" justify="center" style="width: 100%;margin-top: 12px;">
              <span>{{ shareInfo.name }}</span>
            </van-row>
            <fold-file-list
                v-if="shareInfo"
                :loading="loading"
                :share-key="shareKey"
                :share-info="shareInfo"
            ></fold-file-list>
            <template v-if="shareInfo && isExpired">
              <span style="font-weight: 600;">文件已过期~</span>
            </template>
            <template v-if="!shareInfo">
              <span style="font-weight: 600;">文件链接不存在~</span>
            </template>
          </template>
          <van-loading type="spinner" v-if="loading"/>
        </div>
      </div>
      <div class="share-creator" v-if="shareInfo && !shareInfo.englishMode">
        <van-row type="flex" justify="end" style="width: 100%">
          <span>分享人: {{ shareInfo.creator }}</span>
        </van-row>
        <van-row type="flex" justify="end" style="width: 100%">
          <span>分享时间: {{ shareInfo.createTime }}</span>
        </van-row>
      </div>
      <van-row justify="start">
        <a target="_blank" v-if="!shareInfo.englishMode" href="https://www.sdnf.com/" style="color: dodgerblue;font-size: 14px;">
          Copyright @ 2023 山东诺方电子科技有限公司
        </a>
        <a target="_blank" v-if="shareInfo.englishMode" href="http://en.novasensor.cn/" style="color: dodgerblue;font-size: 14px;">
          Copyright @ 2023 SHANDONG NOVA TECHNOLOGY Co.,Ltd.
        </a>
      </van-row>
    </div>
  </div>
</template>

<script>
import FoldFileList from "@/views/components/FoldFileList.vue";
import {
  getFileShareInfo,
} from "@/api/manage";
import {getIconWithExtension, formatSize} from "@/utils/commonFunction";
import Vue from "vue";
import {Loading, Sticky} from "vant";
import moment from "moment";

Vue.use(Loading)
Vue.use(Sticky)
export default {
  name: "PanDownload",
  components: { FoldFileList },
  data() {
    return {
      shareKey: '',
      shareInfo: undefined,
      isExpired: false,
      passwordCode: '',
      isPwdPass: false,
      errorMsg: '',
      downloadInfo: {},
      loading: true,
      shareList: [],
      rootId: undefined,
      parentId: undefined,
      currentFoldId: undefined,
      searchText: undefined,
      fileListRow: null,
      isSearch: false
    }
  },
  computed: {
    folderIcon() {
      return (extension, type) => {
        if (type === 'FOLDER') {
          return '#icon-folder'
        }
        return getIconWithExtension(extension)
      }
    },
    formatSize() {
      return (size) => {
        return formatSize(size)
      }
    },
    expiredTime() {
      return (time) => {
        if (!time) {
          return '--'
        }
        const timeParse = moment(time).diff(moment(), 'days')
        return timeParse <= 0 ? '即将失效' : `${timeParse}天后过期`
      }
    }
  },
  mounted() {
    this.shareKey = this.$route.params.shareKey
    this.passwordCode = this.$route.query.pwd
    this.getShareInfo(this.shareKey)
  },
  methods: {
    getShareInfo(shareKey) {
      getFileShareInfo(shareKey).then(res => {
        this.loading = false
        if (res.code === 200) {
          this.shareInfo = res.data
        } else {
          this.isExpired = true
        }
      })
    },
    toHome() {
      if (this.shareInfo.englishMode) {
        window.open('http://en.novasensor.cn/', '_blank')
      } else {
        window.open('https://www.sdnf.com/', '_blank')
      }
    },
  }
}
</script>

<style scoped lang="less">
.pan-download {
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 170px);
  background-color: #f6f7f9;
}

.icon {
  width: 5em;
  height: 5em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.share-creator {
  //position: absolute;
  bottom: 50px;
  right: 0;
  padding: 12px;
  font-size: 12px;
}

.desc-table {
  width: 312px;
  margin-top: 12px;
}

.password-input {
  border: 1px solid #C0C4CC;
  border-radius: 999px;
  padding: 6px 12px;
  background-color: #ffffff33;
  margin: 12px 0;
  width: 300px;

  ::v-deep .van-field__control {
    min-width: 280px;
    text-align: center;
  }
}

.pwd-check {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.file-small-icon {
  width: 3em;
  height: 3em;
}

.van-cell__title {
  display: flex;
  align-items: flex-start;
  padding: 0px 4px;
  flex-direction: column;
  justify-content: center;
  min-width: 256px;
}

.clickable {
  cursor: pointer;
}

.file-list {
  width: 1366px;
  margin-top: 12px;
  height: 768px;
  overflow: auto;
  max-height: calc(100vh - 310px);
  box-shadow: 0 0 8px #00000024;
  border-radius: 8px;
  background-color: #fff;
}

@media (max-width: 400px) {
  .file-list {
    max-width: 360px;
    max-height: 500px;
  }
}
.title-span {
  text-align: left;
  word-break: break-all;
}

.van-cell__value {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: none;
}

.download-btn {
  cursor: pointer;
  color: dodgerblue;
}
</style>
<style lang="less">
.van-field__body {
  width: 100%;
}
</style>