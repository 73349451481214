<template>
  <div>
      <div class="home" v-if="spaceFinished">
          <van-search
                  v-model="keywords"
                  placeholder="请输入搜索关键词"
                  input-align="center"
                  @focus="toSearch"
          />
          <div style="padding: 12px 7%;">
              <el-breadcrumb separator-class="el-icon-arrow-right">
                  <el-breadcrumb-item>团队空间</el-breadcrumb-item>
              </el-breadcrumb>
          </div>
          <van-pull-refresh v-model="spaceRefreshing" @refresh="reLoadSpace">
              <van-list
                      v-model="spaceLoading"
                      :finished="spaceFinished"
                      finished-text=""
                      @load="getSpaceList"
                      class="content-fill"
              >
                  <div @click="toFilePage(item)" class="file-card" v-for="item in spaceList" :key="'div'+ item.spaceId" slot="default">
                      <van-row type="flex" justify="center" style="width: 100%">
                          <van-col span="4">
                              <svg class="icon" aria-hidden="true">
                                  <use :xlink:href="folderIcon(item.spaceName)"></use>
                              </svg>
                          </van-col>
                          <van-col span="16" class="file-card-title">
                              <span :key="item.spaceId">{{ item.spaceName }}</span>
                              <span class="file-card-title-desc">{{ formatTime(item.modifyTime) }} 更新</span>
                          </van-col>
                          <van-col class="file-card-opr" span="4">
                              <van-icon name="ellipsis" @click.stop="showOprAction(item)"/>
                          </van-col>
                      </van-row>
                  </div>
              </van-list>
          </van-pull-refresh>
          <van-action-sheet
                  v-model="showAction"
                  :actions="spaceActions"
                  cancel-text="取消"
                  close-on-click-action
                  @cancel="showAction = false"
                  @select="selectMenu"
          />
      </div>
      <div v-if="!spaceFinished" style="height: calc(100vh);display: flex;align-items: center;">
          <van-row justify="center" style="width: 100%;">
              <van-col span="24">
                  <van-image
                          width="256"
                          height="56"
                          src="/logo.png"
                          style="margin: 12px;cursor: pointer;"
                          @click="toHome"
                  />
              </van-col>
          </van-row>
      </div>
  </div>
</template>

<script>
import Vue from 'vue';
import {
    Divider,
    Cell,
    List,
    PullRefresh,
    Col,
    Row,
    Icon,
    ActionSheet,
    Search,
    Toast
} from 'vant';

import {getUserSpaceList, sendDentryLinkMsg} from "@/api/manage";
import {formatTime} from "@/utils/commonFunction";
import {corpId, previewDentryShareUrl} from "@/config/publicConfig";
import * as dd from "dingtalk-jsapi";

Vue.use(List);
Vue.use(Cell);
Vue.use(Divider);
Vue.use(PullRefresh);
Vue.use(Col);
Vue.use(Row);
Vue.use(Icon);
Vue.use(ActionSheet);
Vue.use(Search);

export default {
  name: 'Home',
  components: {
  },
  methods: {
    toFilePage(item) {
        this.$router.push({
            path: "/file",
            name: "File",
            query: {
                spaceId: item.spaceId
            }
        })
    },
    selectMenu (e, $event) {
      console.log(e.name, $event)
      const this_ = this
      switch (e.name) {
        case '发送链接':
          dd.ready(function() {
              dd.biz.chat.pickConversation({
                  corpId: corpId, //企业id,必须是用户所属的企业的corpid
                  isConfirm: true,
                  onSuccess : function(result) {
                      console.log(result)
                      if (result && result.cid) {
                          const params = {
                              unionId: this_.dingUserInfo.unionid,
                              cid: result.cid,
                              linkMsg: {
                                  msgtype: 'link',
                                  link: {
                                      title: this_.showActionItem.spaceName,
                                      text: '钉钉云盘/共享文件',
                                      messageUrl: this_.makeDentryUrl(),
                                      picUrl: this_.sendIcon(this_.showActionItem.spaceName)
                                  }
                              }
                          }
                          this_.sendLinkMsg(params)
                      }
                  },
                  onFail : function(err) {
                      // alert(JSON.stringify(err))
                      console.log(JSON.stringify(err))
                  }
              })
          })
          break
        case '复制链接':
        {
          const url = this.makeDentryUrl();
          if (dd.env.platform === 'pc') {
              this.$copyText(url).then(
                  () => {
                      Toast.success('复制成功');
                  },
                  (e)=> {
                      Toast.fail('复制失败');
                      console.error(e)
                  })
          } else {
              dd.ready(function() {
                  dd.biz.clipboardData.setData({
                      text: url, //要复制粘贴板的内容
                      onSuccess : function() {
                          Toast.success('复制成功');
                      },
                      onFail : function(err) {
                          console.error(JSON.stringify(err))
                          Toast.fail('复制失败');
                      }
                  })
              });
          }
          break
        }
      }
    },
    async sendLinkMsg (params) {
        const { data } = await sendDentryLinkMsg(params)
        console.log(data)
    },
    makeDentryUrl () {
        return `${this.previewDentryShareUrl}&spaceId=${this.showActionItem.spaceId}&fileId=0&type=folder`
    },
    toSearch () {
      this.$router.push({
        path: "/search",
        name: "Search"
      })
    },
    showOprAction (item) {
      this.showActionItem = item
      this.showAction = true
    },
    reLoadSpace () {
      this.spaceRefreshing = true
      this.getSpaceList()
    },
    async getSpaceList () {
      if (!sessionStorage.getItem('dingUserInfo')) {
          return
      }
      console.log(this.dingUserInfo)
      this.spaceLoading = false
      this.spaceFinished = false
      const { code, data } = await getUserSpaceList(this.dingUserInfo.unionid)
      if (code === 200) {
        this.spaceList = data
      }
      this.spaceLoading = true
      this.spaceFinished = true
      this.spaceRefreshing = false
    },
    toHome () {
        window.location.href = 'https://www.sdnf.com/'
    }
  },
  computed: {
    dingUserInfo () {
      return JSON.parse(sessionStorage.getItem('dingUserInfo'))
    },
    formatTime () {
      return (time) => {
        return formatTime(time)
      }
    },
    folderIcon () {
      return (name) => {
        if (name === '全员文件夹') {
          return '#icon-wenjianjia'
        }
        return '#icon-gongxiangwenjianjia'
      }
    },
    sendIcon () {
        return (name) => {
            if (name === '全员文件夹') {
                return '@lALPDe7s8jSQci7MyMzI'
            }
            return '@lALPDf0i7MerLYzMyMzI'
        }
    }
  },
  beforeCreate () {
      // 添加对 sessionStorage 的监听
      window.addEventListener("setItem", () => {
          console.log('+++++++')
          this.isDingEnv = sessionStorage.getItem('isDingEnv')
          const ticket = sessionStorage.getItem("ticket");
          if (ticket) {
              this.getSpaceList()
          }
      });
  },
  mounted() {
    this.getSpaceList()
  },
  data() {
    return {
      previewDentryShareUrl,
      showAction: false,
      showActionItem: {},
      spaceActions: [
        {name: '发送链接'},
        {name: '复制链接'},
      ],
      spaceLoading: false,
      spaceRefreshing: false,
      spaceFinished: false,
      spaceList: [],
      keywords: '',
      isDingEnv: false
    };
  },
}
</script>

<style scoped lang="less">
  .content {
    padding: 16px 16px 160px;
  }
  .content-fill {
    height: calc(100vh - 100px);
  }
  @media screen and ( max-width: 500px ) {
    .content-fill {
      height: calc(100vh - 160px);
    }
  }
  .van-pull-refresh {
    overflow: auto;
  }
  .file-card {
    height: 52px;
    display: flex;
    align-items: center;
    padding: 4px 8px;
    &:hover {
      background-color: #EFEFEF;
    }
  }
  .file-card-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .file-card-title-desc {
    font-size: 12px;
    color: #8f99a8;
    font-weight: 500;
  }
  .file-card-title span {
    margin-left: 4px;
    display: -webkit-box;
    overflow: hidden;
    white-space: normal !important;
    text-overflow: ellipsis;
    word-wrap: break-word;
    -webkit-line-clamp: 1;
    /* autoprefixer: off*/
    -webkit-box-orient: vertical;
    /* autoprefixer: on*/
    text-align: left;
    max-width: -webkit-fill-available;
  }
  .file-card-opr {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .icon {
    width: 3em; height: 3em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
  }
  .el-breadcrumb {
    line-height: 2;
  }
</style>