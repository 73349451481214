<template>
  <div>
    <template v-if="shareInfo">
      <template v-if="shareInfo.type === 'FILE'">
        <van-row type="flex" justify="center" style="width: 100%">
          <table class="desc-table" border="1" style="border-collapse: collapse;background-color: #f6f7f9;">
            <tr>
              <td>{{ textDict['fileSize'] }}</td>
              <td>{{ formatSize(shareInfo.size) }}</td>
            </tr>
            <tr>
              <td>{{ textDict['expireDate'] }}</td>
              <td>{{ expiredTime(shareInfo.expireTime) }}</td>
            </tr>
          </table>
        </van-row>
      </template>
      <van-row type="flex" justify="center"
               style="width: 100%;display: flex;flex-direction: column;align-items: center;">
        <van-button @click="downloadFile" v-show="isPwdPass" size="middle" style="width: 256px;margin: 24px 0;"
                    round type="info" v-if="shareInfo.type === 'FILE'">
          {{ textDict['downloadFile'] }}
        </van-button>
        <template v-if="!isPwdPass && shareInfo.needCode">
          <span style="color: orangered;margin-top: 12px;" v-show="!isPwdPass && errorMsg">{{ errorMsg }}</span>
          <div class="pwd-check">
            <van-field
                id="password-input"
                class="password-input"
                v-model="passwordCode"
                label=""
                :placeholder="textDict['codeInputNotice']"
                input-align="left"
                :border="true"
            />
            <van-button @click="checkAndDownloadLink" size="middle" style="width: 256px;margin: 12px 0;" round
                        type="info">
              {{ textDict['extractFile'] }}
            </van-button>
          </div>
        </template>
        <van-row ref="fileListRow" v-if="isPwdPass && shareInfo.type === 'FOLDER'" class="file-list"
        :class="[ shareInfo.simpleMode ? 'simple-mode' : '' ]">
          <van-nav-bar v-if="staticTitle" :title="staticTitle" />
          <van-search
              v-model="searchText"
              :placeholder="textDict['searchNotice']"
              input-align="center"
              shape="round"
              @search="searchShareFile"
              @clear="onSearchCancel"
              :clearable="false"
              v-if="!staticTitle"
          />
          <van-cell value="" @click="backToParent()" v-if="currentFoldId !== rootId && !isSearch" class="clickable">
            <template #title>
              <span>{{ textDict['backToFront'] }}</span>
            </template>
            <template #icon>
              <svg class="file-small-icon" aria-hidden="true">
                <use xlink:href="#icon-folder-top"></use>
              </svg>
            </template>
          </van-cell>
          <template v-for="file in shareList">
            <van-cell value="" :key="file.id" :class="[ file.type === 'FOLDER' ? 'clickable' : '' ]"
                      @click="clickFolder(file)">
              <!-- 使用 title 插槽来自定义标题 -->
              <template #title>
                <span class="title-span" v-if="file.type !== 'FOLDER'" @click.stop="downloadFolderFile(file)">{{ file.name }}</span>
                <span class="title-span" v-else>{{ file.name }}</span>
              </template>
              <template #label>
                <span>{{ formatSize(file.size) }}</span>
              </template>
              <template #default>
                <a class="download-btn" v-if="file.type !== 'FOLDER'"
                   @click.stop="downloadFolderFile(file)">{{ textDict['download'] }}</a>
              </template>
              <template #icon>
                <svg class="file-small-icon" aria-hidden="true">
                  <use :xlink:href="folderIcon(file.extension, file.type)"></use>
                </svg>
              </template>
            </van-cell>
          </template>
          <template v-if="!shareList || shareList.length === 0">
            <div style="height: 100%;display: flex;align-items: center;justify-content: center;">暂无内容</div>
          </template>
        </van-row>
        <van-row type="flex" justify="center" style="width: 100%;margin-top: 12px;" v-if="!shareInfo.simpleMode && !shareInfo.englishMode">
          <span style="font-size: 12px;">提示: 大于100M的文件建议在电脑端浏览器中进行下载</span>
        </van-row>
      </van-row>
    </template>
  </div>
</template>
<script>
import { baseUrl } from "@/config/publicConfig";
import {formatSize, getIconWithExtension} from "@/utils/commonFunction";
import moment from "moment/moment";
import {
  checkDownloadCode,
  folderShareList,
  getFileDownloadLink,
  // getFolderFileDownloadLink,
  searchShareFolderFile
} from "@/api/manage";
import {cn, en} from "@/utils/lang";
export default {
  name: "FoldFileList",
  components: {
  },
  data() {
    return {
      isExpired: false,
      passwordCode: '',
      isPwdPass: false,
      errorMsg: '',
      downloadInfo: {},
      shareList: [],
      rootId: undefined,
      parentId: undefined,
      currentFoldId: undefined,
      searchText: undefined,
      staticTitle: undefined,
      fileListRow: null,
      isSearch: false,
      textDict: {},
      downloading: false
    }
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    shareKey: {
      type: String,
      default: ''
    },
    shareInfo: {
      type: Object,
      default: undefined
    }
  },
  computed: {
    folderIcon() {
      return (extension, type) => {
        if (type === 'FOLDER') {
          return '#icon-folder'
        }
        return getIconWithExtension(extension)
      }
    },
    formatSize() {
      return (size) => {
        return formatSize(size)
      }
    },
    expiredTime() {
      return (time) => {
        if (!time) {
          return '--'
        }
        const timeParse = moment(time).diff(moment(), 'days')
        if (this.shareInfo.englishMode) {
          return timeParse <= 0 ? 'About to expire' : `Expires in ${timeParse} days`
        } else {
          return timeParse <= 0 ? '即将失效' : `${timeParse}天后过期`
        }
      }
    }
  },
  mounted () {
    if (this.shareInfo.englishMode) {
      this.textDict = en
    } else {
      this.textDict = cn
    }
    this.passwordCode = this.$route.query.pwd
    this.searchText = this.$route.query.keyword
    this.staticTitle = this.$route.query.staticTitle
    this.isSearch = false
    if (this.shareInfo.type === 'FOLDER') {
      this.fileListRow = this.$refs.fileListRow
    }
    this.checkAndDownloadLink()
  },
  methods: {
    downloadFile() {
      window.parent.postMessage("downloadStart", "*");
      getFileDownloadLink(this.shareKey, this.passwordCode).then((res) => {
        let blob = new Blob([res], { //设置数据源
          type: 'application/octet-stream'  //设置文件格式
        })
        window.parent.postMessage("downloadSuccess", "*");
        let objectUrl = URL.createObjectURL(blob); //创建下载的链接
        let a = document.createElement("a");
        a.href = objectUrl;
        a.download = this.shareInfo.name; //设置文件名
        //下面这个写法兼容火狐
        a.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
        window.URL.revokeObjectURL(blob); //释放bolb对象
      }).catch((err) => {
        this.$message.error(`数据导出失败，${err.message}`);
      })
    },
    downloadFolderFile(file) {
      window.parent.postMessage("downloadStart", "*");
      const downloadUrl = `${baseUrl}/share/folder/download/${this.shareKey}?code=${this.passwordCode}&entryId=${file.id}`
      console.log(baseUrl, downloadUrl)
      const a = document.createElement("a");
      a.href = downloadUrl;
      a.target = '_blank';
      a.download = file.name; // 设置文件名
      document.body.appendChild(a); // 必须将 a 元素添加到文档中，否则有些浏览器可能无法点击
      a.click();
      document.body.removeChild(a); // 点击后立即移除
      // getFolderFileDownloadLink(this.shareKey, this.passwordCode, file.id).then((res) => {
      //   console.log('=======', res)
      //   const blob = res;
      //   window.parent.postMessage("downloadSuccess", "*");
      //   const objectUrl = URL.createObjectURL(blob);
      //   const a = document.createElement("a");
      //   a.href = objectUrl;
      //   a.target = '_blank';
      //   a.download = file.name; // 设置文件名
      //   document.body.appendChild(a); // 必须将 a 元素添加到文档中，否则有些浏览器可能无法点击
      //   a.click();
      //   document.body.removeChild(a); // 点击后立即移除
      //   setTimeout(() => {
      //     window.URL.revokeObjectURL(objectUrl);
      //   }, 100);
      //
      //
      //   // let blob = new Blob([res], { //设置数据源
      //   //   type: 'application/octet-stream'  //设置文件格式
      //   // })
      //   // window.parent.postMessage("downloadSuccess", "*");
      //   // let objectUrl = URL.createObjectURL(blob); //创建下载的链接
      //   // let a = document.createElement("a");
      //   // a.href = objectUrl;
      //   // a.target = '_blank'
      //   // a.download = file.name; //设置文件名
      //   // //下面这个写法兼容火狐
      //   // a.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
      //   // // window.URL.revokeObjectURL(blob); //释放bolb对象
      //   // setTimeout(() => {
      //   //   window.URL.revokeObjectURL(objectUrl);
      //   // }, 100);
      // }).catch((err) => {
      //   this.$message.error(`数据导出失败，${err.message}`);
      // })
    },
    onSearchCancel () {
      this.searchText = ''
      this.getFileList(this.parentId)
    },
    searchShareFile () {
      if (this.searchText) {
        searchShareFolderFile({
          shareId: this.shareKey,
          code: this.passwordCode,
          parentId: this.rootId,
          searchText: this.searchText ? this.searchText.trim() : '',
        }).then(res => {
          this.isPwdPass = true
          this.shareList = res.data.list
          this.isSearch = true
        })
      } else {
        this.getFileList(this.parentId)
      }
    },
    backToParent() {
      this.getFileList(this.parentId)
    },
    clickFolder(file) {
      console.log(file)
      if (file.type === 'FOLDER') {
        this.getFileList(file.id)
      }
    },
    getFileList(parentId) {
      folderShareList({
        shareId: this.shareKey,
        code: this.passwordCode,
        parentId: parentId
      }).then(res => {
        if (res.code === 200) {
          this.isPwdPass = true
          this.rootId = res.data.rootId
          this.shareList = res.data.list
          if (this.shareInfo.rootId != 0) {
            this.parentId = this.shareInfo.rootId
          } else {
            this.parentId = res.data.parentId
          }
          this.currentFoldId = parentId
          this.isSearch = false
        } else {
          this.errorMsg = res.msg
        }
      })
    },
    checkAndDownloadLink() {
      this.errorMsg = ''
      console.log(this.passwordCode)
      if (this.shareInfo.needCode && !this.passwordCode.trim()) {
        this.errorMsg = '请输入提取码再提交'
        return
      }
      console.log(this.shareKey)
      if (this.shareInfo.type === 'FOLDER') {
        if (this.searchText) {
          searchShareFolderFile({
            shareId: this.shareKey,
            code: this.passwordCode,
            parentId: this.shareInfo.rootId,
            searchText: this.searchText ? this.searchText : ''
          }).then(res => {
            this.isPwdPass = true
            this.rootId = res.data.rootId
            this.shareList = res.data.list
            this.parentId = res.data.parentId
            this.currentFoldId = this.rootId
            this.isSearch = true
          })
        } else {
          folderShareList({
            shareId: this.shareKey,
            code: this.passwordCode
          }).then(res => {
            if (res.code === 200) {
              this.isPwdPass = true
              this.rootId = res.data.rootId
              this.shareList = res.data.list
              this.parentId = res.data.parentId
              this.currentFoldId = this.rootId
              this.isSearch = false
            } else {
              this.errorMsg = res.msg
            }
          })
        }
      } else {
        checkDownloadCode(this.shareKey, this.passwordCode).then(res => {
          if (res.code === 200) {
            this.isPwdPass = true
          } else {
            this.errorMsg = res.msg
          }
        })
      }
    },
  }
}
</script>
<style scoped lang="less">
.pan-download {
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 170px);
  background-color: #f6f7f9;
}

.icon {
  width: 5em;
  height: 5em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.share-creator {
  //position: absolute;
  bottom: 50px;
  right: 0;
  padding: 12px;
  font-size: 12px;
}

.desc-table {
  width: 312px;
  margin-top: 12px;
}

.password-input {
  border: 1px solid #C0C4CC;
  border-radius: 999px;
  padding: 6px 12px;
  background-color: #ffffff33;
  margin: 12px 0;
  width: 300px;

  ::v-deep .van-field__control {
    min-width: 280px;
    text-align: center;
  }
}

.pwd-check {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.file-small-icon {
  width: 3em;
  height: 3em;
}

.van-cell__title {
  display: flex;
  align-items: flex-start;
  padding: 0px 4px;
  flex-direction: column;
  justify-content: center;
  min-width: 256px;
}

.clickable {
  cursor: pointer;
}

.file-list {
  width: 1366px;
  margin-top: 12px;
  height: 768px;
  overflow: auto;
  max-height: calc(100vh - 310px);
  box-shadow: 0 0 8px #00000024;
  border-radius: 8px;
  background-color: #fff;
}
.simple-mode {
  width: 100%;
  height: 100%;
  max-height: none;
  margin-top: 0;
  box-shadow: none;
  border: none;
}

@media (max-width: 400px) {
  .file-list {
    max-width: 360px;
    max-height: 500px;
  }
}
.title-span {
  text-align: left;
  word-break: break-all;
  cursor: pointer;
  &:hover {
    color: dodgerblue;
    text-decoration: underline;
  }
}

.van-cell__value {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: none;
}

.download-btn {
  cursor: pointer;
  color: dodgerblue;
}
</style>
<style lang="less">
.van-field__body {
  width: 100%;
}
</style>