export const cn = {
    fileSize: '文件大小',
    expireDate: '文件时效',
    codeInputNotice: '请输入4位提取码, 不区分大小写',
    extractFile: '提取文件',
    downloadFile: '下载文件',
    searchNotice: '请输入搜索关键词',
    backToFront: '返回上一级',
    download: '下载'
}
export const en = {
    fileSize: 'File Size',
    expireDate: 'Expire Date',
    codeInputNotice: 'Please enter a 4-digit extraction code',
    extractFile: 'Extract file',
    downloadFile: 'Download file',
    searchNotice: 'Please enter the search keyword',
    backToFront: 'Back to previous',
    download: 'Download'
}
