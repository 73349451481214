<template>
    <div>
        <van-nav-bar
                title="订阅信息"
                left-text="返回"
                left-arrow
                @click-left="backToMy"
        />
        <div>
            <van-pull-refresh v-model="fileRefreshing" @refresh="reLoadFile">
                <van-list
                        v-model="fileLoading"
                        :finished="fileFinished"
                        finished-text=""
                        @load="getUserSubscribeList"
                        class="content-fill"
                >
                    <div class="file-card"
                         v-for="item in subscribeList"
                         :key="'div'+ item.noticeId"
                         slot="default">
                        <van-row type="flex" justify="start" style="width: 100%">
                            <van-col span="4" style="display: flex;justify-content: center;">
                                <van-image width="2rem"
                                           height="2rem"
                                           round
                                           fit="cover"
                                           :src="avatar(item.oprUserAvatar)"></van-image>
                            </van-col>
                            <van-col span="20" class="user-time">
                                <span>{{ item.oprUserName }}</span>
                                <span>{{ formatTime(item.createTime) }}</span>
                            </van-col>
                        </van-row>
                        <van-row type="flex" justify="start" class="notice-msg">
                            {{ item.noticeMsg }}
                        </van-row>
                        <van-row v-show="item.changeType !== 4" @click="handleMoveTo(item)" type="flex" justify="center" style="width: 100%"
                                 class="file-info">
                            <van-col span="4" style="display: flex;justify-content: center;">
                                <div class="file-icon">
                                    <svg class="icon" aria-hidden="true">
                                        <use :xlink:href="folderIcon(item.entryType, item.entryExtension)"></use>
                                    </svg>
                                </div>
                            </van-col>
                            <van-col span="20" class="file-card-title">
                                <span :key="item.entryId">{{ item.entryName }}</span>
                                <span class="file-card-title-desc">
                              <template v-if="item.entrySize">
                                  {{ formatSize(item.entrySize) }} &nbsp;
                              </template>
                            </span>
                                <span class="file-card-title-desc">
                                <template v-if="item.entryPath">
                                    {{ item.entryPath }} &nbsp;
                                </template>
                            </span>
                            </van-col>
                        </van-row>
                        <van-row type="flex" justify="end">
                            <van-tag :type="changeType(item.changeType).type" size="medium">
                                {{ changeType(item.changeType).text }}
                            </van-tag>
                        </van-row>
                        <van-divider></van-divider>
                    </div>
                    <van-empty description="无订阅信息" v-if="subscribeList.length === 0"/>
                </van-list>
            </van-pull-refresh>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import {
    Cell,
    Col,
    Divider,
    Icon,
    List,
    Row,
    NavBar,
    Tag, Toast
} from "vant";
import {getDentryInfo, noticeAll} from "@/api/manage";
import {CHANGE_TYPE, formatSize, formatTime, getIconWithExtension} from "@/utils/commonFunction";
import * as dd from "dingtalk-jsapi";
import {corpId} from "@/config/publicConfig";

Vue.use(List);
Vue.use(Cell);
Vue.use(Col);
Vue.use(Row);
Vue.use(Icon);
Vue.use(Divider);
Vue.use(NavBar)
Vue.use(Tag)
export default {
    name: "SubscribeList",
    data() {
        return {
            fileLoading: false,
            fileRefreshing: false,
            fileFinished: true,
            subscribeList: []
        }
    },
    computed: {
        dingUserInfo() {
            return JSON.parse(sessionStorage.getItem('dingUserInfo'))
        },
        folderIcon() {
            return (type, extension) => {
                if (type === 'FOLDER') {
                    return '#icon-folder'
                } else if (type === 'backToTop') {
                    return '#icon-folder-top'
                }
                return getIconWithExtension(extension)
            }
        },
        formatTime() {
            return (time) => {
                if (time) {
                    return formatTime(time.replace('CST', 'GMT+8'))
                }
                return ''
            }
        },
        formatSize() {
            return (size) => {
                return formatSize(size)
            }
        },
        changeType() {
            return (type) => {
                return CHANGE_TYPE[type]
            }
        },
        avatar () {
            return (avatar) => {
                return avatar || './avatar.webp'
            }
        }
    },
    mounted() {
        this.getUserSubscribeList()
    },
    methods: {
        async handleMoveTo(item) {
            console.log(item)
            const fileInfo = await this.getFileInfo(item.entryId)
            if (!fileInfo) {
                Toast.fail('资源已删除!')
                return
            }
            const userSpaces = sessionStorage.getItem('userSpaces').split(',')
            if (!userSpaces.includes(fileInfo.spaceId)) {
                Toast.fail('无权限访问目标空间!')
                return
            }
            switch (item.changeType) {
                // 名称变更和版本变更提供文件预览或文件夹跳转
                case 1:
                case 3: {
                    if (item.entryType === 'FOLDER') {
                        this.moveToFolder(fileInfo)
                    } else {
                        this.previewFile(fileInfo)
                    }
                    break
                }
                // 路径变更跳转到目标目录
                case 2: {
                    this.moveToFolder({
                        spaceId: fileInfo.spaceId,
                        id: fileInfo.parentId
                    })
                    break
                }
                // 删除的文件不做操作
                case 4:
                    break
            }
        },
        async getFileInfo(entryId) {
            const { data } = await getDentryInfo(entryId, this.dingUserInfo.unionid)
            return data
        },
        previewFile (fileInfo) {
            console.log(fileInfo)
            dd.ready(function () {
                dd.biz.cspace.preview({
                    corpId: corpId,
                    spaceId: fileInfo.spaceId,
                    fileId: fileInfo.id,
                    fileName: fileInfo.name,
                    fileSize: fileInfo.size,
                    fileType: fileInfo.extension,
                    onSuccess: function (res) {
                        console.log(res)
                    },
                    onFail: function (err) {
                        console.error(err)
                        Toast.fail('预览失败')
                    }
                });
            })
        },
        moveToFolder (fileInfo) {
            console.log(fileInfo)
            this.$router.push({
                path: "/file",
                name: "File",
                query: {
                    spaceId: fileInfo.spaceId,
                    parentId: fileInfo.id
                }
            })
        },
        reLoadFile() {
            this.fileRefreshing = true
            this.getUserSubscribeList()
        },
        backToMy() {
            this.$router.push({
                path: "/my",
                name: "My",
            })
        },
        getUserSubscribeList() {
            this.fileLoading = true
            noticeAll(this.dingUserInfo.unionid).then(res => {
                this.fileLoading = false
                this.fileFinished = true
                this.fileRefreshing = false
                if (res.code === 200) {
                    this.subscribeList = res.data
                }
            })
        }
    }
}
</script>

<style scoped lang="less">
.content-fill {
  height: calc(100vh - 100px);
}

@media screen and ( max-width: 500px ) {
  .content-fill {
    height: calc(100vh - 140px);
  }
}

.van-pull-refresh {
  overflow: auto;
}

.file-card {
  padding: 6px 12px;
}

.file-info {
  border-radius: 8px;
  padding: 8px 0;
  margin: 4px 0;

  &:hover {
    background-color: #EFEFEF;
  }
}

.file-card-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.file-card-title-desc {
  font-size: 12px;
  color: #8f99a8;
  font-weight: 500;
}

.file-card-title span {
  margin-left: 4px;
  display: -webkit-box;
  overflow: hidden;
  white-space: normal !important;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: 1;
  /* autoprefixer: off*/
  -webkit-box-orient: vertical;
  /* autoprefixer: on*/
  text-align: left;
  max-width: -webkit-fill-available;
}

.icon {
  width: 3em;
  height: 3em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.user-time {
  display: flex;
  justify-content: space-between;
  color: #909399;
  align-items: center;
}

.notice-msg {
  text-align: left;
  color: #909399;
  padding: 4px 12px;
  font-size: 14px;
}

.van-divider {
  margin: 8px 0;
}
</style>