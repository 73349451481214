const corpId = 'ding7601f86cfceed6e335c2f4657eb6378f'
const appKey = 'dingce7vl2cm3osltmgq'
const appSecret = '0_1tSVagdWBMN5cPxuyTYbb4qEJDXfVS1nUKsAu5spOqFARiT3zqpushBJSrhx-t'
const agentId = 2528607755

const baseUrl = '/api'

const nonceStr = 'novaStorage'

const previewDentryShareUrl = 'https://qr.dingtalk.com/page/yunpan?route=previewDentry'

export {
    corpId,
    appKey,
    appSecret,
    agentId,
    baseUrl,
    previewDentryShareUrl,
    nonceStr
}