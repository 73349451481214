import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 全局引入main.js
import VueClipboard from 'vue-clipboard2'
Vue.use(ElementUI)
Vue.use(VueClipboard)
Vue.config.productionTip = false

Vue.prototype.setSessionItem = function (key, newVal) {
    // 创建 StorageEvent 事件
    let newStorageEvent = document.createEvent("StorageEvent");
    const storage = {
        setItem: function (k, val) {
            sessionStorage.setItem(k, val);

            // 初始化 StorageEvent 事件
            newStorageEvent.initStorageEvent(
                "setItem", // 事件别名
                false,
                false,
                k,
                null,
                val,
                null,
                null
            );

            // 派发事件
            window.dispatchEvent(newStorageEvent);
        },
    };
    return storage.setItem(key, newVal)
};

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
