<template>
    <div>
        <van-nav-bar
                title="分享信息"
                left-text="返回"
                left-arrow
                @click-left="backToFront"
        />
        <div class="main-content">
            <van-row type="flex" justify="center" style="width: 100%">
                <div class="file-icon">
                    <svg class="icon" aria-hidden="true">
                        <use :xlink:href="folderIcon(shareInfo.extension, shareInfo.type)"></use>
                    </svg>
                </div>
                <div>
                </div>
            </van-row>
            <van-row type="flex" justify="center" style="width: 100%; margin: 12px;">
                <span>{{ shareInfo.name }}</span>
            </van-row>
            <van-row id="description" type="flex" justify="center" style="width: 100%;">
                <van-cell-group inset style="max-width: 400px;min-width: 330px;" :center="true">
                    <van-cell title="文件时效" :is-link="!shareInfo.expired" @click="changeExpiredTime">
                        <template slot="default">
                            <van-count-down :time="timeRemain" format="DD天HH:mm:ss后失效" v-if="!shareInfo.expired && timeRemain"/>
                            <span style="color: orangered;" v-if="shareInfo.expired || timeRemain <= 0">已过期</span>
                            <span v-if="!shareInfo.expired && timeRemain === undefined">永久有效</span>
                        </template>
                    </van-cell>
                    <van-cell title="提取码">
                        <template slot="default">
                            <span style="font-weight: 500;">{{ shareInfo.code || '无' }}</span>
                        </template>
                        <template slot="extra">
                            <span style="color: #1989fa;" v-if="!shareInfo.expired" @click="copyContent(shareInfo.code)">复制</span>
                        </template>
                    </van-cell>
                    <van-cell title="下载次数">
                        <template slot="default">
                            <span style="font-weight: 500;">{{ shareInfo.downloadNum + '次' }}</span>
                        </template>
                    </van-cell>
                    <van-cell title="搜索屏蔽文件夹">
                        <template slot="default">
                            <van-switch v-model="shareInfo.shieldFolder" @change="toggleMode($event, 'shieldFolder')"/>
                        </template>
                    </van-cell>
                    <van-cell title="嵌入视图">
                        <template slot="default">
                            <van-switch v-model="shareInfo.simpleMode" @change="toggleMode($event, 'simpleMode')"/>
                        </template>
                    </van-cell>
                  <van-cell title="英文模式">
                    <template slot="default">
                      <van-switch v-model="shareInfo.englishMode" @change="toggleMode($event, 'englishMode')"/>
                    </template>
                  </van-cell>
                    <van-cell title="备注" :is-link="!shareInfo.expired" @click="changeRemark">
                        <template slot="default">
                            <span style="font-weight: 500;">{{ shareInfo.remark }}</span>
                        </template>
                    </van-cell>
                </van-cell-group>
            </van-row>
            <van-row type="flex" justify="center" style="width: 100%;">
                <div class="btn-container">
                    <van-button @click.stop="cancelShare" style="width: 150px;" hairline plain round type="danger">
                        取消分享
                    </van-button>
                    <van-button v-if="!shareInfo.expired" @click.stop="copyUrl" style="width: 150px;text-align: left;" hairline plain round type="info">
                        复制链接
                    </van-button>
                    <van-button v-if="shareInfo.expired" @click.stop="activeShare" style="width: 150px;text-align: left;" hairline plain round type="primary">
                        激活分享
                    </van-button>
                </div>
            </van-row>
        </div>
        <van-popup cancel-text="取消" :closeable="true"  v-model="showValidity" round position="bottom">
            <div :style="{ padding: '8px' }">
                <div style="margin: 8px;">
                    <span>有效期设置</span>
                </div>
                <template v-for="item in limitTypeDict">
                    <van-cell value="" @click="chooseLimitType(item.type)" :key="item.type">
                        <!-- 使用 title 插槽来自定义标题 -->
                        <template #title>
                            <div class="validity-content">
                                <div v-html="item.text" style="float: left;"></div>
                                <div class="validity-desc">{{ item.description }}</div>
                            </div>
                        </template>
                        <template #right-icon>
                            <van-icon color="#42b983" name="success" class="check-icon" v-show="currentValidity === item.type" />
                        </template>
                    </van-cell>
                </template>
                <van-button @click.stop="updateShareExpireDate" style="width: 100%; margin: 12px 0;" hairline round type="info">
                    确定
                </van-button>
            </div>
        </van-popup>
        <van-popup v-model="showDateSelect" round position="bottom">
            <van-row type="flex" style="width: 100%">
                <van-datetime-picker
                    v-model="customLimitDate"
                    type="date"
                    title="选择年月日"
                    :min-date="minDate"
                    style="width: 100%;"
                    @cancel="cancelDateChoose"
                    @confirm="chooseCustomDate"
                />
            </van-row>
        </van-popup>
        <van-popup v-model="showRemarkEdit" round position="bottom">
            <div :style="{ padding: '8px' }">
                <van-row id="remark-editor" type="flex" style="width: 100%">
                    <van-field
                        v-model="remark"
                        label=""
                        placeholder="请描述本次分享的用途, 方便后期维护"
                        :border="true"
                        rows="1"
                        autosize
                        maxlength="50"
                        show-word-limit
                        type="textarea"
                    />
                </van-row>
                <van-button @click.stop="editRemark" style="width: 100%; margin: 12px 0;" hairline round type="info">
                    确定
                </van-button>
            </div>
        </van-popup>
    </div>
</template>
<script>
import Vue from "vue";
import {Dialog, Toast, CountDown} from "vant";
import {cancelShare, expireUpdate, shareDetail, toggleMode} from "@/api/manage";
import {formatTime, getIconWithExtension} from "@/utils/commonFunction";
import moment from "moment/moment";
import * as dd from "dingtalk-jsapi";
Vue.use(CountDown)
export default {
    name: "ShareInfo",
    data() {
        return {
            type: 'all',
            rightText: '全选',
            shareList: [],
            shareInfo: {},
            showOpr: false,
            shareKey: '',
            timeRemain: undefined,
            showValidity: false,
            showDateSelect: false,
            showRemarkEdit: false,
            limitTypeDict: [
                { type: 'sevenDay', text: '<span style="color: #409eff;font-weight: 600;">7</span> 天内有效' },
                { type: 'fourteen', text: '<span style="color: #409eff;font-weight: 600;">14</span> 天内有效' },
                { type: 'thirty', text: '<span style="color: #409eff;font-weight: 600;">30</span> 天内有效' },
                { type: 'forever', text: '永久有效', description: '在手动取消前，分享将会持续有效， 重要文件不建议勾选' },
                {
                    type: 'custom',
                    text: '<span style="color: #409eff;font-weight: 600;">自定义</span>到期日',
                    description: ''
                }
            ],
            chooseTime: '',
            minDate: moment().add(1, 'day').toDate(),
            customLimitDate: '',
            currentValidity: 'custom',
            remark: ''
        }
    },
    computed: {
        dingUserInfo() {
            return JSON.parse(sessionStorage.getItem('dingUserInfo'))
        },
        folderIcon() {
            return (extension, type) => {
                if (type === 'FOLDER') {
                  return '#icon-folder'
                }
                return getIconWithExtension(extension)
            }
        },
        formatTime() {
            return (time) => {
                if (time) {
                    return formatTime(time.replace('CST', 'GMT+8'))
                }
                return ''
            }
        },
        expiredTime() {
            return (time) => {
                if (!time) {
                    return '永久有效'
                }
                const timeParse = moment(time).diff(moment(), 'days')
                if (timeParse === 0) {
                    return '即将失效'
                } else if (timeParse > 0) {
                    return `${timeParse} 天后过期`
                } else if (timeParse < -180) {
                    return '过期超过 180 天'
                } else {
                    return `已过期 ${Math.abs(timeParse)} 天`
                }
            }
        }
    },
    mounted () {
        this.shareKey = this.$route.query.shareKey
        this.getShareInfo()
    },
    methods: {
        toggleMode (value, type) {
          console.log(value, type)
          toggleMode({
            type: type,
            value: value,
            shareId:this.shareKey
          }).then(res => {
            if (res.code === 200) {
              Toast.success('更新成功!')
              this.getShareInfo()
            } else {
              Toast.fail('更新失败!')
            }
          })
        },
        activeShare () {
            // 重新设置时间
            this.currentValidity = 'thirty'
            this.showValidity = true
        },
        updateShareExpireDate () {
            if (this.currentValidity === 'custom' && !this.customLimitDate) {
                Toast.fail('请选择到期日期!')
                this.showDateSelect = true
                return
            }
            const params = {
                unionId: this.dingUserInfo.unionid,
                forever: false
            }
            switch (this.currentValidity) {
                case 'sevenDay':
                    params.expireTime = moment().add(6, 'day').endOf('day').format('yyyy-MM-DD HH:mm:ss')
                    break
                case 'fourteen':
                    params.expireTime = moment().add(13, 'day').endOf('day').format('yyyy-MM-DD HH:mm:ss')
                    break
                case 'thirty':
                    params.expireTime = moment().add(29, 'day').endOf('day').format('yyyy-MM-DD HH:mm:ss')
                    break
                case 'custom':
                    params.expireTime = moment(this.customLimitDate).endOf('day').format('yyyy-MM-DD HH:mm:ss')
                    break
                case 'forever':
                    params.forever = true
                    break
            }
            expireUpdate(this.shareKey, params).then(res => {
                if (res.code === 200) {
                    Toast.success('更新成功!')
                    this.getShareInfo()
                    this.showValidity = false
                } else {
                    Toast.fail('更新失败!')
                }
            })
        },
        chooseLimitType (type) {
            switch (type) {
                case 'custom':
                    this.showDateSelect = true
                    break
                default:
                    this.customLimitDate = ''
                    this.limitTypeDict[4].description = ''
            }
            this.currentValidity = type
        },
        chooseCustomDate (value) {
            console.log(value)
            this.limitTypeDict[4].description = moment(value).format('yyyy年MM月DD日')
            this.customLimitDate = value
            this.showDateSelect = false
        },
        cancelDateChoose () {
            this.showDateSelect = false
        },
        editRemark () {
            const params = {
                unionId: this.dingUserInfo.unionid,
                onlyRemark: true,
                remark: this.remark
            }
            expireUpdate(this.shareKey, params).then(res => {
                if (res.code === 200) {
                    Toast.success('更新成功!')
                    this.getShareInfo()
                    this.showRemarkEdit = false
                } else {
                    Toast.fail('更新失败!')
                }
            })
        },
        changeRemark () {
            if (this.shareInfo.expired) {
                return
            }
            this.remark = this.shareInfo.remark
            this.showRemarkEdit = true
        },
        changeExpiredTime () {
            if (this.shareInfo.expired) {
                return
            }
            if (this.shareInfo.expireTime) {
                this.currentValidity = 'custom'
                this.customLimitDate = moment(this.shareInfo.expireTime).toDate()
                console.log(moment(this.shareInfo.expireTime).toDate())
                this.limitTypeDict[4].description = moment(this.shareInfo.expireTime).format('yyyy年MM月DD日')
            } else {
                this.currentValidity = 'forever'
            }

            this.showValidity = true
        },
        copyUrl () {
            const item = this.shareInfo
            const baseUrl = `${window.location.origin}/#/download`
            // 请求key
            const key = item.key
            if (key) {
                const this_ = this
                let url = `${baseUrl}/${key}`
                if (item.needCode) {
                    Dialog.confirm({
                        title: '是否开启『自动填充提取码』',
                        message: '开启后访问者无需输入提取码，可直接查看分享文件',
                    }).then(() => {
                        url += `?pwd=${item.code}`
                        this_.copyContent (url)
                    }).catch(() => {
                        this_.copyContent (url)
                    })
                } else {
                    this_.copyContent (url)
                }
            }
        },
        copyContent (url) {
            if (dd.env.platform === 'pc' || dd.env.platform === 'notInDingTalk') {
                this.$copyText(url).then(
                    () => {
                        Toast.success('复制成功');
                    },
                    (e) => {
                        Toast.fail('复制失败');
                        console.error(e)
                    })
            } else {
                dd.ready(function () {
                    dd.biz.clipboardData.setData({
                        text: url, //要复制粘贴板的内容
                        onSuccess: function () {
                            Toast.success('复制成功');
                        },
                        onFail: function (err) {
                            console.error(JSON.stringify(err))
                            Toast.fail('复制失败');
                        }
                    })
                });
            }
        },
        cancelShare () {
            const this_ = this
            Dialog.confirm({
                title: '',
                message: '取消分享后，该链接将从列表中清除，且无法被继续访问，是否继续？',
            }).then(() => {
                cancelShare(this_.dingUserInfo.unionid, this_.shareKey).then(res => {
                    if (res.code === 200) {
                        Toast.success('取消成功!')
                        this_.backToFront()
                    } else {
                        Toast.fail('取消失败!')
                    }
                })
            }).catch(() => {
                // on cancel
            })
        },
        backToFront () {
            this.$router.back()
        },
        async getShareInfo() {
            const { code, data } = await shareDetail(this.dingUserInfo.unionid, this.shareKey)
            if (code === 200) {
                this.shareInfo = data
                if (data.expireTime) {
                    this.timeRemain = moment(data.expireTime).diff(moment(), 'millisecond');
                } else {
                    this.timeRemain = undefined
                }
            }
        }
    }
}
</script>
<style scoped lang="less">
.main-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: calc(100vh - 100px);
    background-color: #f6f7f9;
}
.file-icon {
    .icon {
        width: 5em;
        height: 5em;
        vertical-align: -0.15em;
        fill: currentColor;
        overflow: hidden;
    }
}
#description {
    ::v-deep {
        .van-cell__title {
            max-width: 120px;
        }
    }
}
.van-cell__title, .van-cell__value {
    display: flex;
    text-align: left;
    word-break: break-all;
}
#remark-editor {
    ::v-deep .van-cell__value {
        display: block !important;
    }
}
.btn-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 400px;
    min-width: 350px;
    padding: 16px;
    position: absolute;
    bottom: 120px;
}
.validity-desc {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #abb3bf;
    font-size: 12px;
    font-weight: 500;
}
.validity-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: max-content;
}
</style>
<style lang="less">
</style>