import request from '@/utils/request'

const api = {
  getUserInfoByCode: '/storage/getUserInfoByCode',
  getUserSpaceList: '/storage/getUserSpaceList',
  getJsApiTicket: '/ding/getJsApiTicket',
  signTicketSign: '/ding/signTicketSign',
  sendDentryLinkMsg: '/storage/sendDentryLinkMsg',
  getUserFileList: '/storage/entries/direct',
  getFolderBreadcrumb: '/storage/breadcrumb',
  subscribeEvent: '/storage/subscribe',
  getDentryInfo: '/storage/entry',
  currentUserInfo: '/storage/currentUserInfo',
  tagUrl: '/storage/tag',
  dentryTags: '/storage/entry/tag',
  searchFile: '/storage/search',
  noticeNow: '/storage/notice/now',
  noticeAll: '/storage/notice/user',
  noticeDetail: '/storage/notice',
  noticeRead: '/storage/notice/read',
  repairUserData: '/storage/repairUserData',
  checkDownloadPermission: '/storage/permission/download',
  checkFolderDownloadPermission: '/storage/permission/download/folder',
  share: '/share',
  download: '/share/download',
  check: '/share/check',
  sharing: '/share/sharing',
  myShare: '/share/list',
  cancelShare: '/share/cancel',
  shareDetail: '/share/detail',
  expireUpdate: '/share/expire',
  folderShareList: '/share/sharing/folder',
  downloadFolderFile: '/share/folder/download',
  searchShareFolderFile: '/share/search',
  toggleMode: '/share/toggleMode'
}

export function getUserInfoByCode (code) {
  return request({
    url: api.getUserInfoByCode,
    method: 'get',
    params: { code: code }
  })
}
export function getUserSpaceList (unionId) {
  return request({
    url: api.getUserSpaceList,
    method: 'get',
    params: { unionId: unionId }
  })
}
export function repairUserData (unionId) {
  return request({
    url: api.repairUserData,
    method: 'get',
    params: { unionId: unionId }
  })
}
export function searchFile (params) {
  return request({
    url: api.searchFile,
    method: 'get',
    params: params
  })
}
export function getUserTagList (unionId) {
  return request({
    url: `${api.tagUrl}/${unionId}`,
    method: 'get'
  })
}
export function noticeNow (unionId) {
  return request({
    url: `${api.noticeNow}/${unionId}`,
    method: 'get'
  })
}
export function noticeAll (unionId) {
  return request({
    url: `${api.noticeAll}/${unionId}`,
    method: 'get'
  })
}
export function getDentryTags (entryId, unionId) {
  return request({
    url: `${api.dentryTags}/${entryId}/${unionId}`,
    method: 'get'
  })
}
export function currentUserInfo (unionId) {
  return request({
    url: api.currentUserInfo,
    method: 'get',
    params: { unionId: unionId }
  })
}
export function getUserFileList (spaceId, parentId, unionId) {
  return request({
    url: api.getUserFileList,
    method: 'get',
    params: { spaceId: spaceId, parentId: parentId, unionId: unionId }
  })
}
export function getJsApiTicket () {
  return request({
    url: api.getJsApiTicket,
    method: 'get'
  })
}
export function signTicketSign (params) {
  return request({
    url: api.signTicketSign,
    method: 'post',
    params: params
  })
}
export function createTag (params) {
  return request({
    url: api.tagUrl,
    method: 'post',
    data: params
  })
}
export function updateTag (params) {
  return request({
    url: api.tagUrl,
    method: 'put',
    data: params
  })
}
export function deleteTag (tagId) {
  return request({
    url: `${api.tagUrl}/${tagId}`,
    method: 'delete'
  })
}
export function markDentryTag (params) {
  return request({
    url: api.dentryTags,
    method: 'post',
    data: params
  })
}
export function deleteDentryTag (params) {
  return request({
    url: api.dentryTags,
    method: 'delete',
    data: params
  })
}
export function sendDentryLinkMsg (params) {
  return request({
    url: api.sendDentryLinkMsg,
    method: 'post',
    data: params
  })
}
export function getFolderBreadcrumb (spaceId, folderId) {
  return request({
    url: `${api.getFolderBreadcrumb}/${spaceId}/${folderId}`,
    method: 'get'
  })
}
export function subscribeEvent (params) {
  return request({
    url: api.subscribeEvent,
    method: 'post',
    data: params
  })
}
export function userSubscribe (unionId) {
  return request({
    url: `${api.subscribeEvent}/${unionId}`,
    method: 'get'
  })
}
export function getDentryInfo (entryId, unionId) {
  return request({
    url: `${api.getDentryInfo}/${entryId}/${unionId}`,
    method: 'get'
  })
}
export function checkDownloadPermission (params) {
  return request({
    url: api.checkDownloadPermission,
    method: 'get',
    params: params
  })
}
export function checkFolderDownloadPermission (params) {
  return request({
    url: api.checkFolderDownloadPermission,
    method: 'get',
    params: params
  })
}
export function createShare (params) {
  return request({
    url: api.share,
    method: 'post',
    data: params
  })
}
export function getFileShareInfo (shareKey) {
  return request({
    url: `${api.share}/${shareKey}`,
    method: 'get'
  })
}
export function getFileDownloadLink (shareKey, pwd) {
  return request({
    url: `${api.download}/${shareKey}`,
    method: 'get',
    params: {
      code: pwd
    },
    responseType: 'blob'
  })
}
export function getFolderFileDownloadLink (shareKey, pwd, entryId) {
  return request({
    url: `${api.downloadFolderFile}/${shareKey}`,
    method: 'get',
    params: {
      code: pwd,
      entryId: entryId
    },
    responseType: 'blob'
  })
}
export function checkDownloadCode (shareKey, pwd) {
  return request({
    url: `${api.check}/${shareKey}`,
    method: 'get',
    params: {
      code: pwd
    }
  })
}
export function getFileSharingList (unionId, entryId) {
  return request({
    url: `${api.sharing}/${unionId}/${entryId}`,
    method: 'get'
  })
}
export function getMyShareList (unionId, type) {
  return request({
    url: `${api.myShare}/${type}/${unionId}`,
    method: 'get'
  })
}
export function cancelShare (unionId, shareIds) {
  return request({
    url: `${api.cancelShare}/${unionId}`,
    method: 'delete',
    params: {
      shareIds: shareIds
    }
  })
}
export function shareDetail (unionId, shareId) {
  return request({
    url: `${api.shareDetail}/${shareId}/${unionId}`,
    method: 'get'
  })
}
export function expireUpdate (shareId, params) {
  return request({
    url: `${api.expireUpdate}/${shareId}`,
    method: 'put',
    data: params
  })
}
export function folderShareList (params) {
  return request({
    url: `${api.folderShareList}`,
    method: 'get',
    params: params
  })
}
export function searchShareFolderFile (params) {
  return request({
    url: `${api.searchShareFolderFile}`,
    method: 'get',
    params: params
  })
}
export function toggleMode (params) {
  return request({
    url: `${api.toggleMode}/${params.shareId}`,
    method: 'post',
    params: params
  })
}