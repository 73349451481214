import { render, staticRenderFns } from "./PanDownload.vue?vue&type=template&id=620556a4&scoped=true&"
import script from "./PanDownload.vue?vue&type=script&lang=js&"
export * from "./PanDownload.vue?vue&type=script&lang=js&"
import style0 from "./PanDownload.vue?vue&type=style&index=0&id=620556a4&prod&scoped=true&lang=less&"
import style1 from "./PanDownload.vue?vue&type=style&index=1&id=620556a4&prod&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "620556a4",
  null
  
)

export default component.exports