import moment from 'moment'
import Vue from 'vue'
import {agentId, corpId, nonceStr} from "@/config/publicConfig";
import * as dd from "dingtalk-jsapi";
import {getUserInfoByCode, signTicketSign} from "@/api/manage";
import {Notify} from "vant";

export function formatTime (time) {
    const sendTime = new Date(time).getTime()
    const sendMonth = new Date(sendTime).getMonth()
    const sendDate = new Date(sendTime).getDate()
    const sendYear = new Date(sendTime).getFullYear()
    let sendHour = new Date(sendTime).getHours()
    let sendMinutes = new Date(sendTime).getMinutes()
    if (sendHour < 10) {
        sendHour = `0${sendHour}`
    }
    if (sendMinutes < 10) {
        sendMinutes = `0${sendMinutes}`
    }
    const timeDiff = new Date().getTime() - sendTime
    const minDiff = ~~(timeDiff / (1000 * 60))
    if (minDiff <= 60) {
        if (minDiff < 1) return '刚刚'
        return `${minDiff}分钟前`
    } else if (moment(sendTime).isSame(moment(), 'day')) {
        return `今天 ${sendHour}:${sendMinutes}`
    } else if (moment(sendTime).isSame(moment().subtract(1, 'day'), 'day')) {
        return `昨天 ${sendHour}:${sendMinutes}`
    } else if (!moment(sendTime).isSame(moment(), 'year')) {
        return `${sendYear}年${sendMonth + 1}月${sendDate}日 ${sendHour}:${sendMinutes}`
    } else {
        return `${sendMonth + 1}月${sendDate}日 ${sendHour}:${sendMinutes}`
    }
}

export function checkIfNoLogin() {
    // Vue.prototype.setSessionItem('dingUserInfo', "{\"device_id\":\"0abdbedcff8d51c3b752f95cb1715432\",\"name\":\"李盛昌\",\"sys\":true,\"sys_level\":2,\"unionid\":\"GDlLhgg4FQfD4r0jGlqIEAiEiE\",\"userid\":\"15953782304435707\"}")
    // window.setItem('dingUserInfo', "{\"device_id\":\"0abdbedcff8d51c3b752f95cb1715432\",\"name\":\"李盛昌\",\"sys\":true,\"sys_level\":2,\"unionid\":\"GDlLhgg4FQfD4r0jGlqIEAiEiE\",\"userid\":\"15953782304435707\"}")
    Vue.prototype.setSessionItem('isDingEnv', false)

    console.log(corpId)
    dd.ready(function () {
        dd.runtime.permission.requestAuthCode({
            corpId: corpId,
            onSuccess: function (result) {
                Vue.prototype.setSessionItem('isDingEnv', true)
                getUserInfo(result)
            },
            onFail: function (err) {
                console.log(err)
            }
        })
    });
    Vue.prototype.setSessionItem('isDingEnv', true)
}

export async function getAndAuthConfig() {
    // 获取Ticket
    const ticket = sessionStorage.getItem('ticket')
    const timeStamp = new Date().getTime()
    const params = {
        ticket: ticket,
        url: window.location.href,
        timeStamp: timeStamp
    }
    console.log('================')
    const { data } = await signTicketSign(params)
    console.log(params, data)
    if (data) {
        dd.ready(function () {
            dd.config({
                agentId: agentId, // 必填，微应用ID
                corpId: corpId,//必填，企业ID
                timeStamp: timeStamp, // 必填，生成签名的时间戳
                nonceStr: nonceStr, // 必填，自定义固定字符串。
                signature: data, // 必填，签名
                type: 0,   //选填。0表示微应用的jsapi,1表示服务窗的jsapi；不填默认为0。该参数从dingtalk.js的0.8.3版本开始支持
                jsApiList: [
                    'runtime.permission.requestJsApis',
                    'runtime.info',
                    'biz.chat.pickConversation',
                    'biz.clipboardData.setData',
                    'biz.cspace.preview',
                ] // 必填，需要使用的jsapi列表，注意：不要带dd。
            });

            dd.error(function (err) {
                alert('dd error: ' + JSON.stringify(err));
            })//该方法必须带上，用来捕获鉴权出现的异常信息，否则不方便排查出现的问题
        })
    }
}
async function getUserInfo(result) {
    console.log(result)
    const {data} = await getUserInfoByCode(result.code)
    const userInfo = JSON.parse(data.userInfo)
    console.log('=======', data)
    if (userInfo.errcode === 0) {
        console.log(JSON.parse(JSON.stringify(userInfo.result)))
        Vue.prototype.setSessionItem('dingUserInfo', JSON.stringify(userInfo.result))
        Vue.prototype.setSessionItem('ticket', data.ticket)
    } else {
        Notify(userInfo.errmsg)
    }
    getAndAuthConfig()
}

export function getIconWithExtension (extension) {
    if (extension) {
        extension = extension.toLowerCase()
    }
    if (['txt'].includes(extension)) {
        return '#icon-txt'
    }
    if (['pdf'].includes(extension)) {
        return '#icon-pdf'
    }
    if (['doc', 'docx'].includes(extension)) {
        return '#icon-word'
    }
    if (['xls', 'xlsx'].includes(extension)) {
        return '#icon-excel'
    }
    if (['ppt', 'pptx'].includes(extension)) {
        return '#icon-ppt'
    }
    if (['bmp', 'dib', 'pcp', 'dif', 'wmf', 'gif', 'jpg', 'jpeg', 'tif', 'eps', 'psd', 'cdr', 'iff', 'tga', 'pcd', 'mpt', 'png'].includes(extension)) {
        return '#icon-image'
    }
    if (['mp3', 'wma', 'wav', 'ape', 'flac', 'ogg', 'aac'].includes(extension)) {
        return '#icon-radio'
    }
    if (['avi', 'wmv', 'mpeg', 'mp4', 'm4v', 'mov', 'asf', 'flv', 'f4v', 'rmvb', 'rm', '3gp', 'vob'].includes(extension)) {
        return '#icon-vedio'
    }
    if (['rar', 'zip', '7z', 'cab', 'arj', 'lzh', 'tar', 'gz', 'ace', 'uue', 'bz2', 'jar', 'iso', 'mpq'].includes(extension)) {
        return '#icon-zip'
    }
    return '#icon-weizhiwenjian'
}
export function getSendIconWithExtension (extension) {
    extension = extension.toLowerCase()
    if (['txt'].includes(extension)) {
        return '@lALPDf0i7NPG4aDMyMzI'
    }
    if (['pdf'].includes(extension)) {
        return '@lALPDe7s8kCvPmbMyMzI'
    }
    if (['doc', 'docx'].includes(extension)) {
        return '@lALPDeREdlJammnMyMzI'
    }
    if (['xls', 'xlsx'].includes(extension)) {
        return '@lALPDetfc5vkK2nMyMzI'
    }
    if (['ppt', 'pptx'].includes(extension)) {
        return '@lALPDfmVbi8AlEHMyMzI'
    }
    if (['bmp', 'dib', 'pcp', 'dif', 'wmf', 'gif', 'jpg', 'jpeg', 'tif', 'eps', 'psd', 'cdr', 'iff', 'tga', 'pcd', 'mpt', 'png'].includes(extension)) {
        return '@lALPDeC2962czfjMyMzI'
    }
    if (['mp3', 'wma', 'wav', 'ape', 'flac', 'ogg', 'aac'].includes(extension)) {
        return '@lALPDfJ6cOV93oHMyMzI'
    }
    if (['avi', 'wmv', 'mpeg', 'mp4', 'm4v', 'mov', 'asf', 'flv', 'f4v', 'rmvb', 'rm', '3gp', 'vob'].includes(extension)) {
        return '@lALPDgCwa3iPPjHMyMzI'
    }
    if (['rar', 'zip', '7z', 'cab', 'arj', 'lzh', 'tar', 'gz', 'ace', 'uue', 'bz2', 'jar', 'iso', 'mpq'].includes(extension)) {
        return '@lALPDf0i7PSj5fXMyMzI'
    }
    return '@lALPDf0i7NPTgR7MyMzI'
}

export function formatSize (size) {
    if (!size)
        return "";

    var num = 1024.00
    if (size < num)
        return size + "B";
    if (size < Math.pow(num, 2))
        return (size / num).toFixed(2) + "K"; //kb
    if (size < Math.pow(num, 3))
        return (size / Math.pow(num, 2)).toFixed(2) + "M"; //M
    if (size < Math.pow(num, 4))
        return (size / Math.pow(num, 3)).toFixed(2) + "G"; //G
    return (size / Math.pow(num, 4)).toFixed(2) + "T"; //T
}

export const FILE_TYPE = {
    doc: ['txt', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'],
    image: ['bmp', 'dib', 'pcp', 'dif', 'wmf', 'gif', 'jpg', 'jpeg', 'tif', 'eps', 'psd', 'cdr', 'iff', 'tga', 'pcd', 'mpt', 'png'],
    audio: ['mp3', 'wma', 'wav', 'ape', 'flac', 'ogg', 'aac'],
    video: ['avi', 'wmv', 'mpeg', 'mp4', 'm4v', 'mov', 'asf', 'flv', 'f4v', 'rmvb', 'rm', '3gp', 'vob'],
    pack: ['rar', 'zip', '7z', 'cab', 'arj', 'lzh', 'tar', 'gz', 'ace', 'uue', 'bz2', 'jar', 'iso', 'mpq']
}

export const CHANGE_TYPE = {
    1: { text: '名称变更', type: 'primary' },
    2: { text: '路径变更', type: 'warning' },
    3: { text: '版本变更', type: 'success' },
    4: { text: '文件删除', type: 'danger' },
}