<template>
  <div></div>
</template>

<script>
export default {
    name: "refresh",
    inject: ['reload'],
    mounted () {
        console.log(this.$route.query)
        this.spaceId = this.$route.query.spaceId
        this.parentId = this.$route.query.parentId || 0
        this.toFolder()
    },
    data () {
        return {
            spaceId: 0,
            parentId: 0
        }
    },
    methods: {
        toFolder () {
            this.$router.replace({
                path: "/file",
                name: "File",
                query: {
                    spaceId: this.spaceId,
                    parentId: this.parentId
                }
            }).then(() => {
                this.reload()
            })
        }
    }
}
</script>

<style scoped>

</style>