<template>
    <div>
        <div @click="clickCard(fileInfo)" class="file-card" :key="'div'+ fileInfo.id">
            <van-row type="flex" justify="center" style="width: 100%">
                <van-col span="4" style="display: flex;justify-content: center;">
                    <div class="file-icon">
                        <svg class="icon" aria-hidden="true">
                            <use :xlink:href="folderIcon(fileInfo.type, fileInfo.extension)"></use>
                        </svg>
                        <svg v-if="fileInfo.subscribed" style="width: 1.3em;" class="icon subscribe" aria-hidden="true">
                            <use xlink:href="#icon-dingyue"></use>
                        </svg>
                    </div>
                </van-col>
                <van-col span="16" class="file-card-title">
                    <span :key="fileInfo.id">{{ fileInfo.name }}</span>
                    <span class="file-card-title-desc" v-if="!fromSearch">
                          <template v-if="fileInfo.size">
                              {{ formatSize(fileInfo.size) }} &nbsp;
                          </template>
                          <template v-if="fileInfo.creator">
                              {{ fileInfo.creator }} &nbsp;
                          </template>
                          <template v-if="fileInfo.modifiedTime">
                              {{ formatTime(fileInfo.modifiedTime) }} 更新
                          </template>
                   </span>
                    <span class="file-card-title-desc" v-if="fromSearch">
                        <template v-if="fileInfo.path ">
                            {{ fileInfo.path }} &nbsp;
                        </template>
                    </span>
                </van-col>
                <van-col class="file-card-opr" span="4" @click.stop="showOprAction(fileInfo)">
                    <van-icon v-if="fileInfo.type !== 'backToTop'" name="ellipsis"/>
                </van-col>
            </van-row>
        </div>
        <van-action-sheet
                v-model="showAction"
                :actions="fileActions"
                cancel-text="取消"
                close-on-click-action
                @cancel="showAction = false"
                @select="selectMenu"
        >
            <div slot="description">
                <van-row type="flex" justify="center" style="width: 100%" v-if="showAction">
                    <van-col span="4">
                        <svg class="icon" aria-hidden="true">
                            <use :xlink:href="folderIcon(showActionItem.type, showActionItem.extension)"></use>
                        </svg>
                    </van-col>
                    <van-col span="20" class="file-card-title">
                        <span :key="showActionItem.id">{{ showActionItem.name }}</span>
                        <span class="file-card-title-desc">
                            <template v-if="showActionItem.size">
                                {{ formatSize(showActionItem.size) }} &nbsp;
                            </template>
                            <template v-if="showActionItem.creator">
                                {{ showActionItem.creator }} &nbsp;
                            </template>
                            <template v-if="showActionItem.modifiedTime">
                                {{ formatTime(showActionItem.modifiedTime) }} 更新
                            </template>
                        </span>
                    </van-col>
                </van-row>
                <van-row type="flex" justify="start" style="width: 100%" v-if="fromSearch">
                    <van-col span="4"></van-col>
                    <van-col span="20" class="file-card-title">
                        <span class="file-card-title-desc">
                            <template v-if="showActionItem.path">
                                {{ showActionItem.path }} &nbsp;
                            </template>
                        </span>
                    </van-col>
                </van-row>
                <van-row type="flex" justify="center" style="width: 100%">
                    <van-tag @click.stop="manageFileTag" plain type="primary"><i class="iconfont icon-dianji"></i>管理标签
                    </van-tag>
                    <van-tag :key="tag.tagId" v-for="tag in dentryTagList" :type="tag.tagColor" size="large">
                        {{ tag.tagName }}
                    </van-tag>
                </van-row>
            </div>
        </van-action-sheet>
        <van-popup v-model="showSubscribe" round position="bottom">
            <div :style="{ padding: '8px' }">
                <van-row type="flex" justify="center" style="width: 100%" v-if="showSubscribe">
                    <van-col span="4">
                        <svg class="icon" aria-hidden="true">
                            <use :xlink:href="folderIcon(showActionItem.type, showActionItem.extension)"></use>
                        </svg>
                    </van-col>
                    <van-col span="16" class="file-card-title">
                        <span :key="showActionItem.id">{{ showActionItem.name }}</span>
                        <span class="file-card-title-desc">
                            <template v-if="showActionItem.size">
                                {{ formatSize(showActionItem.size) }} &nbsp;
                            </template>
                            <template v-if="showActionItem.creator">
                                {{ showActionItem.creator }} &nbsp;
                            </template>
                            <template v-if="showActionItem.modifiedTime">
                                {{ formatTime(showActionItem.modifiedTime) }} 更新
                            </template>
                        </span>
                    </van-col>
                </van-row>
                <van-checkbox-group v-model="subscribeResult">
                    <van-cell-group>
                        <van-cell
                                v-for="(item, index) in subscribeActions"
                                clickable
                                :key="item.name"
                                :title="item.title"
                                @click="toggle(index)"
                        >
                            <template #right-icon>
                                <van-checkbox :name="item.name" ref="subscribeCheck"/>
                            </template>
                        </van-cell>
                    </van-cell-group>
                </van-checkbox-group>
                <van-button @click="saveSubscribe" style="width: 100%;" round type="info">保存&emsp;</van-button>
            </div>
        </van-popup>
        <van-popup v-model="showTagManage" round position="bottom">
            <div :style="{ padding: '24px' }">
                <van-row type="flex">
                    <span>已标记：</span>
                    <van-tag closeable @close="removeDentryTag(tag)" :key="tag.tagId" v-for="tag in dentryTagList"
                             :type="tag.tagColor" size="large">{{ tag.tagName }}
                    </van-tag>
                </van-row>
                <van-divider></van-divider>
                <van-row type="flex">
                    <span>我的标签：</span>
                    <van-popover @open="createNewTag" v-model="showAddTag" trigger="click" placement="top">
                        <template slot="reference">
                            <van-tag plain type="success" size="large"><i class="iconfont icon-dianji"></i>新增标签
                            </van-tag>
                        </template>
                        <div :style="{ padding: '16px' }">
                            <van-row>
                                <van-field ref="tagName" required v-model="tagInfo.tagName" :border="true"
                                           placeholder="请输入标签名" maxlength="10" :show-word-limit="true"/>
                            </van-row>
                            <van-row style="margin: 16px 0;">
                                <van-radio-group v-model="tagInfo.tagColor" direction="horizontal">
                                    <van-radio name="primary">
                                        <van-tag type="primary" size="medium">&emsp;</van-tag>
                                    </van-radio>
                                    <van-radio name="success">
                                        <van-tag type="success" size="medium">&emsp;</van-tag>
                                    </van-radio>
                                    <van-radio name="danger">
                                        <van-tag type="danger" size="medium">&emsp;</van-tag>
                                    </van-radio>
                                    <van-radio name="warning">
                                        <van-tag type="warning" size="medium">&emsp;</van-tag>
                                    </van-radio>
                                </van-radio-group>
                            </van-row>
                            <van-button size="small" @click="handleSaveTag" style="width: 100%;" round type="info">
                                保存
                            </van-button>
                        </div>
                    </van-popover>
                    <van-tag @click="markTag(tag)" :key="tag.tagId" v-for="tag in myTagList" :type="tag.tagColor"
                             size="large">{{ tag.tagName }}
                    </van-tag>
                </van-row>
            </div>
        </van-popup>
        <van-popup v-model="showShare" round position="bottom">
            <div :style="{ padding: '8px' }">
                <div>外部分享</div>
                <van-row type="flex" style="width: 100%">
                    <van-col span="6" style="display: flex;justify-content: center;">
                        <div class="share" @click="generateUrl">
                            <div class="share-btn"><i class="iconfont icon-chaolianjie"></i></div>
                            <span>复制链接</span>
                        </div>
                    </van-col>
                    <van-col span="6" style="display: flex;justify-content: center;">
                        <div class="share" @click="generateQRCode">
                            <div class="share-btn"><i class="iconfont icon-erweima"></i></div>
                            <span>生成二维码</span>
                        </div>
                    </van-col>
                    <van-col v-show="sharingCount" span="6" style="display: flex;justify-content: center;position: relative;">
                        <div class="share" @click="showSharingUrl">
                            <div class="share-btn"><i class="iconfont icon-history"></i></div>
                            <span>历史分享</span>
                        </div>
                        <van-badge :content="sharingCount" style="position: absolute;bottom: 18px;"/>
                    </van-col>
                </van-row>
                <van-row type="flex" style="width: 100%">
                    <div class="share-config">
                        <van-row type="flex" style="width: 100%;margin: 12px 0;" @click="clickValidity">
                            <van-col span="16" class="flex-start">有效期</van-col>
                            <van-col span="8" class="flex-end"><div v-html="limitText"></div>  <i class="iconfont icon-xiayibu"></i></van-col>
                        </van-row>
                        <van-row type="flex" style="width: 100%;margin: 12px 0;" @click="choosePasswordType">
                            <van-col span="16" class="flex-start">{{ passwordText }}</van-col>
                            <van-col span="8" class="flex-end"><div class="blue-span">{{ passwordCode }}</div>  <i class="iconfont icon-xiayibu"></i></van-col>
                        </van-row>
                        <van-row type="flex" style="width: 100%;margin: 12px 0;">
                            <van-col span="16" class="flex-start">
                                分享链接自动填充提取码
                                <van-popover v-model="showPasswordNotice" trigger="click" placement="top" :close-on-click-outside="true">
                                    <span>开启后分享链接会带上提取码，用户点击链接可自动填充提取码。</span>
                                    <template #reference>
                                        <van-icon name="question-o" />
                                    </template>
                                </van-popover>
                            </van-col>
                            <van-col span="8" class="flex-end"><van-switch v-model="autoPassword" size="18px"/></van-col>
                        </van-row>
                        <van-row type="flex" style="width: 100%;margin: 12px 0;align-items: center;">
                            <van-col span="4" class="flex-start">备注</van-col>
                            <van-col span="20" class="flex-end">
                                <van-field
                                    v-model="remark"
                                    label=""
                                    placeholder="请描述本次分享的用途, 方便后期维护"
                                    :border="true"
                                    rows="1"
                                    autosize
                                    maxlength="50"
                                    show-word-limit
                                    type="textarea"
                                />
                            </van-col>
                        </van-row>
                    </div>
                </van-row>
            </div>
        </van-popup>
        <van-action-sheet v-model="showValidity" title="有效期设置" round :closeable="false" position="bottom">
            <div :style="{ padding: '8px' }">
                <template v-for="item in limitTypeDict">
                    <van-cell value="" @click="chooseLimitType(item.type)" :key="item.type">
                        <!-- 使用 title 插槽来自定义标题 -->
                        <template #title>
                            <div class="validity-content">
                                <div v-html="item.text" style="float: left;"></div>
                                <div class="validity-desc">{{ item.description }}</div>
                            </div>
                        </template>
                        <template #right-icon>
                            <van-icon color="#42b983" name="success" class="check-icon" v-show="currentValidity === item.type" />
                        </template>
                    </van-cell>
                </template>
            </div>
        </van-action-sheet>
        <van-popup v-model="showDateSelect" round position="bottom">
                <van-row type="flex" style="width: 100%">
                    <van-datetime-picker
                        v-model="customLimitDate"
                        type="date"
                        title="选择年月日"
                        :min-date="minDate"
                        style="width: 100%;"
                        @cancel="cancelDateChoose"
                        @confirm="chooseCustomDate"
                    />
                </van-row>
        </van-popup>
        <van-action-sheet @close="closePasswordSelect" v-model="showPasswordSelect" title="提取码设置" round :closeable="false" position="bottom">
            <div :style="{ padding: '8px' }">
                    <van-cell value="系统随机生成提取码" @click="choosePassword('random')" >
                        <template #right-icon>
                            <van-icon color="#42b983" name="success" class="check-icon" v-show="currentPasswordType === 'random'" />
                        </template>
                    </van-cell>
                    <van-cell value="" @click="choosePassword('empty')" >
                        <!-- 使用 title 插槽来自定义标题 -->
                        <template #title>
                            <div class="validity-content">
                                <div style="float: left;">不设置提取码</div>
                                <div class="validity-desc">
                                    重要文件请勿选择此选项
                                </div>
                            </div>
                        </template>
                        <template #right-icon>
                            <van-icon color="#42b983" name="success" class="check-icon" v-show="currentPasswordType === 'empty'" />
                        </template>
                    </van-cell>
                    <van-cell value="" @click="choosePassword('custom')" >
                        <!-- 使用 title 插槽来自定义标题 -->
                        <template #title>
                            <div class="validity-content">
                                <div style="float: left;">自定义提取码</div>
                                <div class="validity-desc" v-show="currentPasswordType === 'custom'">
                                    <van-field
                                        id="password-input"
                                        class="password-input"
                                        v-model="passwordCode"
                                        label=""
                                        placeholder="请输入4位提取码, 仅支持数字及英文字母"
                                        input-align="left"
                                        :border="true"
                                        @input="commitPassword = false"
                                    />
                                    <span style="color: orangered;" v-show="commitPassword && !checkPassword()">{{ passwordErrorMsg }}</span>
                                </div>
                            </div>
                        </template>
                        <template #right-icon>
                            <van-icon color="#42b983" name="success" class="check-icon" v-show="currentPasswordType === 'custom'" />
                        </template>
                    </van-cell>
            </div>
        </van-action-sheet>
        <van-action-sheet v-model="showUrlCopy" title="复制分享链接" round :closeable="false" position="bottom">
            <div style="padding: 8px;">
                <div class="final-url"><span>{{ finalUrl }}</span></div>
                <van-button size="small" @click="copyContent(finalUrl)" style="width: 100%;margin: 12px 0;" round type="info">
                    复制链接
                </van-button>
            </div>
        </van-action-sheet>
        <van-action-sheet v-model="showQrGen" title="二维码生成" round :closeable="false" position="bottom">
            <div class="qr-contain">
                <div ref="qrCodeUrl">
                </div>
                <van-button v-show="isEnvPC && showQrSaveBtn" size="small" @click="saveQRCode()" style="width: 100%;margin: 12px 0;" round type="info">
                    保存图片
                </van-button>
                <span v-show="!isEnvPC" style="color: #8f99a8;font-weight: 500;">长按二维码保存图片</span>
            </div>
        </van-action-sheet>
        <van-action-sheet v-model="showSharning" title="我的分享" round :closeable="false" position="bottom">
            <van-cell-group inset>
                <van-cell @click="copyUrl(item)" style="cursor: pointer;text-align: left;" title="单元格" value-class="a-value" value="复制链接" v-for="item in sharingList" :key="item.key">
                    <template #title>
                        <div style="display: flex;flex-direction: column;align-items: flex-start;min-width: 250px;">
                            <span class="custom-title">分享时间: {{ item.createTime }}</span>
                            <span class="custom-title">下载次数: {{ item.downloadNum }}</span>
                            <span class="custom-title" v-show="item.needCode">提取码: {{ item.code }}</span>
                            <span class="custom-title">有效期: {{ expiredTime(item.expireTime) }}</span>
                            <span class="custom-title" v-show="item.remark">备注: {{ item.remark }}</span>
                        </div>
                    </template>
                </van-cell>
            </van-cell-group>
        </van-action-sheet>
    </div>
</template>

<script>
import {formatSize, formatTime, getIconWithExtension, getSendIconWithExtension} from "@/utils/commonFunction";
import * as dd from "dingtalk-jsapi";
import moment from 'moment'
import Vue from "vue";
import QRCode from 'qrcodejs2'
import html2canvas from 'html2canvas'
import {
    Checkbox,
    CheckboxGroup,
    Col,
    Divider,
    Field,
    Form,
    Icon,
    Popup,
    Row,
    Tag,
    Toast,
    Popover,
    RadioGroup,
    Radio,
    DatetimePicker,
    Switch,
    Badge, Dialog
} from "vant";
import {corpId, previewDentryShareUrl} from "@/config/publicConfig";
import {
  checkDownloadPermission, checkFolderDownloadPermission,
  createShare,
  createTag, deleteDentryTag,
  getDentryInfo, getDentryTags, getFileSharingList,
  getFolderBreadcrumb,
  getUserTagList, markDentryTag,
  sendDentryLinkMsg,
  subscribeEvent
} from "@/api/manage";

Vue.use(Col);
Vue.use(Row);
Vue.use(Icon);
Vue.use(Popup);
Vue.use(Form);
Vue.use(Field);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Tag);
Vue.use(Divider);
Vue.use(Popover);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(DatetimePicker);
Vue.use(Switch);
Vue.use(Badge);
export default {
    name: "FileCard",
    inject: ['reload'],
    props: {
        fileInfo: {
            type: Object
        },
        fromSearch: {
            type: Boolean,
            default: () => false
        }
    },
    computed: {
        dingUserInfo() {
            return JSON.parse(sessionStorage.getItem('dingUserInfo'))
        },
        formatTime() {
            return (time) => {
                if (time) {
                    return formatTime(time.replace('CST', 'GMT+8'))
                }
                return ''
            }
        },
        formatSize() {
            return (size) => {
                return formatSize(size)
            }
        },
        folderIcon() {
            return (type, extension) => {
                if (type === 'FOLDER') {
                    return '#icon-folder'
                } else if (type === 'backToTop') {
                    return '#icon-folder-top'
                }
                return getIconWithExtension(extension)
            }
        },
        sendIcon() {
            return (type, extension) => {
                if (type === 'FOLDER') {
                    return '@lALPDeREdlJdY6fMyMzI'
                }
                return getSendIconWithExtension(extension)
            }
        },
        currEnv() {
            return dd.env.platform
        },
        limitText () {
            const res = this.limitTypeDict.filter(limit => {
                return limit.type === this.currentValidity
            })
            return res.length > 0 ? res[0].text : ''
        },
        passwordText () {
            const res = this.passwordTypeDict.filter(types => {
                return types.type === this.currentPasswordType
            })
            return res.length > 0 ? res[0].text : ''
        },
        isEnvPC () {
            return dd.env.platform === 'pc' || dd.env.platform === 'notInDingTalk'
        },
        expiredTime() {
            return (time) => {
                if (!time) {
                    return '永久有效'
                }
                const timeParse = moment(time).diff(moment(), 'days')
                if (timeParse === 0) {
                    return '即将失效'
                } else if (timeParse > 0) {
                    return `${timeParse} 天后过期`
                } else if (timeParse < -180) {
                    return '过期超过 180 天'
                } else {
                    return `已过期 ${Math.abs(timeParse)} 天`
                }
            }
        }
    },
    data() {
        return {
            previewDentryShareUrl,
            showAction: false,
            showActionItem: {},
            fileActions: [
                {name: '发送链接'},
                {name: '复制链接'},
                {name: '订阅更新'},
                {name: '外部分享'},
            ],
            showSubscribe: false,
            subscribeActions: [
                {title: '名称变更', name: 'eName'},
                {title: '版本变更', name: 'eVersion'},
                {title: '文件删除', name: 'eDelete'},
                {title: '目录变更', name: 'eFolder'},
            ],
            subscribeResult: [],
            showTagManage: false,
            showAddTag: false,
            showShare: false,
            showValidity: false,
            showDateSelect: false,
            showPasswordSelect: false,
            showUrlCopy: false,
            showQrGen: false,
            showQrSaveBtn: false,
            showSharning: false,
            tagInfo: {
                tagName: '',
                tagColor: 'primary'
            },
            myTagList: [],
            dentryTagList: [],
            existIds: [],
            randomCode: '73z2',
            limitTypeDict: [
                { type: 'sevenDay', text: '<span style="color: #409eff;font-weight: 600;">7</span> 天内有效' },
                { type: 'fourteen', text: '<span style="color: #409eff;font-weight: 600;">14</span> 天内有效' },
                { type: 'thirty', text: '<span style="color: #409eff;font-weight: 600;">30</span> 天内有效' },
                { type: 'forever', text: '永久有效', description: '在手动取消前，分享将会持续有效， 重要文件不建议勾选' },
                {
                    type: 'custom',
                    text: '<span style="color: #409eff;font-weight: 600;">自定义</span>到期日',
                    description: ''
                }
            ],
            passwordTypeDict: [
                { type: 'random', text: '随机生成提取码' },
                { type: 'empty', text: '不设置提取码' },
                { type: 'custom', text: '自定义提取码' },
            ],
            currentValidity: 'forever',
            currentPasswordType: 'random',
            chooseTime: '',
            minDate: moment().add(1, 'days').toDate(),
            customLimitDate: '',
            passwordCode: '',
            remark: '',
            commitPassword: false,
            passwordErrorMsg: '',
            autoPassword: true,
            showPasswordNotice: false,
            finalUrl: '',
            sharingCount: 0,
            sharingList: []
        }
    },
    mounted() {
    },
    methods: {
        showSharingUrl () {
            this.showSharning = true
        },
        async createShareData() {
            const params = {
                key: this.showActionItem.id,
                unionId: this.dingUserInfo.unionid,
                limitType: this.currentValidity,
                limitDay: this.calcLimitDay(),
                passwordType: this.currentPasswordType,
                password: this.passwordCode,
                remark: this.remark
            }
            if (this.currentValidity === 'custom') {
                params.expireTime = moment(this.customLimitDate).add(1, 'day').add(-1, 'second').format('yyyy-MM-DD HH:mm:ss')
            }
            console.log(params)
            const res = await createShare(params)
            if (res.code !== 200) {
                Toast.fail('分享生成失败, 请返回重试!')
            }
            return res.data
        },
        async checkDownloadPermission () {
            return await checkDownloadPermission({
                spaceId: this.showActionItem.spaceId,
                entryId: this.showActionItem.id,
                unionId: this.dingUserInfo.unionid
            })
        },
        async checkFolderDownloadPermission () {
            return await checkFolderDownloadPermission({
                spaceId: this.showActionItem.spaceId,
                entryId: this.showActionItem.id,
                unionId: this.dingUserInfo.unionid
            })
        },
        saveQRCode() {
            html2canvas(this.$refs.qrCodeUrl).then(canvas => {
                const dataUrl = canvas.toDataURL('image/png')
                const img = new Image()
                img.src = dataUrl
                const link = document.createElement('a')
                link.download = 'qrcode.png'
                link.href = dataUrl
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
        },
        async generateQRCode() {
            this.showQrSaveBtn = false
            this.showQrGen = true
            await this.handleGenUrl()
            console.log(this.$refs)
            setTimeout(() => {
                this.$refs.qrCodeUrl.innerHTML = ''
                new QRCode(this.$refs.qrCodeUrl, {
                    width: 200,
                    height: 200,
                    text: this.finalUrl
                })
                this.showQrSaveBtn = true
                this.showShare = false
            }, 500)
        },
        async handleGenUrl() {
            const baseUrl = `${window.location.origin}/#/download`
            // 请求key
            const key = await this.createShareData()
            if (key) {
                let url = `${baseUrl}/${key}`
                if (this.autoPassword) {
                    url += `?pwd=${this.passwordCode}`
                }
                this.finalUrl = url
            }
        },
        async generateUrl() {
            await this.handleGenUrl()
            this.copyContent(this.finalUrl)
            this.showUrlCopy = true
            this.showShare = false
        },
        copyUrl (item) {
            const baseUrl = `${window.location.origin}/#/download`
            // 请求key
            const key = item.key
            if (key) {
                const this_ = this
                let url = `${baseUrl}/${key}`
                if (item.needCode) {
                    Dialog.confirm({
                        title: '是否开启『自动填充提取码』',
                        message: '开启后访问者无需输入提取码，可直接查看分享文件',
                    }).then(() => {
                        url += `?pwd=${item.code}`
                        this_.copyContent (url)
                    }).catch(() => {
                        this_.copyContent (url)
                    })
                } else {
                    this_.copyContent (url)
                }
            }
        },
        copyContent (url) {
            if (dd.env.platform === 'pc' || dd.env.platform === 'notInDingTalk') {
                this.$copyText(url).then(
                    () => {
                        Toast.success('复制成功');
                    },
                    (e) => {
                        Toast.fail('复制失败');
                        console.error(e)
                    })
            } else {
                dd.ready(function () {
                    dd.biz.clipboardData.setData({
                        text: url, //要复制粘贴板的内容
                        onSuccess: function () {
                            Toast.success('复制成功');
                        },
                        onFail: function (err) {
                            console.error(JSON.stringify(err))
                            Toast.fail('复制失败');
                        }
                    })
                });
            }
        },
        checkPassword () {
            if (!this.passwordCode || !this.passwordCode.trim()) {
                return false
            }
            if (/^[0-9a-zA-Z]{4}$ /.test(this.passwordCode) === false) {
                return false
            }
            return true
        },
        closePasswordSelect () {
            if (this.currentPasswordType === 'custom') {
                if (!this.passwordCode || !this.passwordCode.trim()) {
                    this.showPasswordSelect = true
                    this.commitPassword = true
                    this.passwordErrorMsg = '请输入4位提取码'
                    return
                }
                if (/^[0-9a-zA-Z]{4}$/.test(this.passwordCode) === false) {
                    this.showPasswordSelect = true
                    this.commitPassword = true
                    this.passwordErrorMsg = '请输入4位提取码, 仅支持数字及英文字母'
                    return
                }
            }
        },
        choosePassword (type) {
            switch (type) {
                case 'random':
                    this.passwordCode = this.generateRandomCode()
                    break
                case 'empty':
                    this.passwordCode = ''
                    break
                case 'custom':
                    this.passwordCode = ''
                    break
            }
            this.currentPasswordType = type
        },
        generateRandomCode () {
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let result = '';
            for (let i = 0; i < 4; i++) {
                result += characters.charAt(Math.floor(Math.random() * characters.length));
            }
            return result;
        },
        chooseCustomDate (value) {
            this.limitTypeDict[4].description = moment(value).format('yyyy年MM月DD日')
            this.customLimitDate = value
            this.showDateSelect = false
        },
        cancelDateChoose () {
            this.showDateSelect = false
        },
        choosePasswordType () {
            this.commitPassword = false
            this.showPasswordSelect = true
        },
        calcLimitDay () {
            let limitDay = 0
            switch (this.currentValidity) {
                case 'sevenDay':
                    limitDay = 7
                    break
                case 'fourteen':
                    limitDay = 14
                    break
                case 'thirty':
                    limitDay = 30
                    break
                case 'custom':
                    limitDay = null
                    break
                case 'forever':
                    limitDay = null
                    break
            }
            return limitDay
        },
        chooseLimitType (type) {
            switch (type) {
                case 'custom':
                    this.showDateSelect = true
                    break
                default:
                    this.customLimitDate = ''
                    this.limitTypeDict[4].description = ''
            }
            this.currentValidity = type
        },
        clickValidity () {
            this.customLimitDate = ''
            this.limitTypeDict[4].description = ''
            this.showValidity = true
        },
        removeDentryTag(tag) {
            const params = {
                entryId: this.showActionItem.id,
                tagId: tag.tagId,
                unionId: this.dingUserInfo.unionid
            }
            deleteDentryTag(params).then(async res => {
                if (res.code === 200) {
                    Toast.success('操作成功!')
                    await this.fileTagList()
                    this.userTagList()
                } else {
                    Toast.fail('操作失败!')
                }
            })
        },
        async markTag(tag) {
            const params = {
                entryId: this.showActionItem.id,
                tagIds: [tag.tagId],
                unionId: this.dingUserInfo.unionid
            }
            markDentryTag(params).then(async res => {
                if (res.code === 200) {
                    Toast.success('标记成功!')
                    await this.fileTagList()
                    this.userTagList()
                } else {
                    Toast.fail('标记失败!')
                }
            })
        },
        async fileTagList() {
            const {data} = await getDentryTags(this.showActionItem.id, this.dingUserInfo.unionid)
            console.log(data)
            this.dentryTagList = data
            this.existIds = this.dentryTagList.map(tag => {
                return tag.tagId
            })
        },
        async userTagList() {
            const {data} = await getUserTagList(this.dingUserInfo.unionid)
            console.log(data)
            this.myTagList = data.filter(tag => {
                return !this.existIds.includes(tag.tagId)
            })
        },
        createNewTag() {
            this.tagInfo = {
                tagName: '',
                tagColor: 'primary'
            }
        },
        handleSaveTag() {
            if (!this.tagInfo.tagName || !this.tagInfo.tagName.trim()) {
                this.$refs.tagName.focus()
                Toast.fail('请输入标签名!')
                return
            }
            this.tagInfo.unionId = this.dingUserInfo.unionid
            createTag(this.tagInfo).then(res => {
                if (res.code === 200) {
                    Toast.success('创建成功!')
                    this.userTagList()
                    this.showAddTag = false
                } else {
                    Toast.fail('创建失败!')
                }
            })
        },
        manageFileTag() {
            this.userTagList()
            this.showTagManage = true
        },
        clickCard(item) {
            console.log(item)
            const this_ = this
            if (item.type === 'FOLDER') {
                this.$router.replace({
                    path: "/file",
                    name: "File",
                    query: {
                        spaceId: item.spaceId,
                        parentId: item.id
                    }
                }).then(() => {
                    this_.reload()
                })
            } else if (item.type === 'backToTop') {
                if (!item.parentId && item.parentId !== '0') {
                    this.$router.replace({
                        path: "/home",
                        name: "Home",
                    }).then(() => {
                        this_.reload()
                    })
                } else {
                    this.$router.replace({
                        path: "/file",
                        name: "File",
                        query: {
                            spaceId: item.spaceId,
                            parentId: item.parentId
                        }
                    }).then(() => {
                        this_.reload()
                    })
                }
            } else {
                dd.ready(function () {
                    dd.biz.cspace.preview({
                        corpId: corpId,
                        spaceId: item.spaceId,
                        fileId: item.id,
                        fileName: item.name,
                        fileSize: item.size,
                        fileType: item.extension,
                        onSuccess: function (res) {
                            console.log(res)
                        },
                        onFail: function (err) {
                            console.error(err)
                            Toast.fail('预览失败')
                        }
                    });
                })
            }
        },
        async selectMenu(e, $event) {
            console.log(e.name, $event)
            const this_ = this
            switch (e.name) {
                case '发送链接':
                    dd.ready(function () {
                        dd.biz.chat.pickConversation({
                            corpId: corpId, //企业id,必须是用户所属的企业的corpid
                            isConfirm: true,
                            onSuccess: async function (result) {
                                console.log(result)
                                if (result && result.cid) {
                                    const {data} = await getFolderBreadcrumb(this_.showActionItem.spaceId, this_.showActionItem.id)
                                    console.log(data)
                                    const params = {
                                        unionId: this_.dingUserInfo.unionid,
                                        cid: result.cid,
                                        linkMsg: {
                                            msgtype: 'link',
                                            link: {
                                                title: this_.showActionItem.name,
                                                text: `钉钉云盘/共享文件/${data.breadcrumb}`,
                                                messageUrl: this_.makeDentryUrl(),
                                                picUrl: this_.sendIcon(this_.showActionItem.type, this_.showActionItem.extension)
                                            }
                                        }
                                    }
                                    this_.sendLinkMsg(params)
                                }
                            },
                            onFail: function (err) {
                                // alert(JSON.stringify(err))
                                console.log(JSON.stringify(err))
                            }
                        })
                    })
                    break
                case '复制链接': {
                    const url = this.makeDentryUrl();
                    if (dd.env.platform === 'pc') {
                        this.$copyText(url).then(
                            () => {
                                Toast.success('复制成功');
                            },
                            (e) => {
                                Toast.fail('复制失败');
                                console.error(e)
                            })
                    } else {
                        dd.ready(function () {
                            dd.biz.clipboardData.setData({
                                text: url, //要复制粘贴板的内容
                                onSuccess: function () {
                                    Toast.success('复制成功');
                                },
                                onFail: function (err) {
                                    console.error(JSON.stringify(err))
                                    Toast.fail('复制失败');
                                }
                            })
                        });
                    }
                    break
                }
                case '订阅更新':
                    this_.getSubscribeItem()
                    break;
                case '外部分享': {
                    let permissionCheck
                    if (this.showActionItem.type === 'FOLDER') {
                      permissionCheck = await this.checkFolderDownloadPermission()
                    } else {
                      permissionCheck = await this.checkDownloadPermission()
                    }
                    if (permissionCheck.data) {
                        this_.passwordCode = this.generateRandomCode()
                        this_.currentPasswordType = 'random'
                        this_.currentValidity = 'thirty'
                        this.remark = ''
                        this.finalUrl = ''
                        this_.showShare = true
                        const sharing = await getFileSharingList(this.dingUserInfo.unionid, this.showActionItem.id)
                        console.log(sharing.data)
                        this.sharingCount = sharing.data.length
                        this.sharingList = sharing.data
                    } else {
                        Toast.fail('无下载权限!!')
                    }
                    break;
                }
            }
        },
        showOprAction(item) {
            this.showActionItem = item
            console.log(item)
            const fileActions = [
                {name: '发送链接'},
                {name: '复制链接'},
                {name: '订阅更新'}
            ]
            // if (item.type !== 'FOLDER') {
                fileActions.push({name: '外部分享'})
            // }
            this.fileActions = fileActions
            this.fileTagList()
            this.showAction = true
        },
        makeDentryUrl() {
            console.log(this.showActionItem)
            return `${this.previewDentryShareUrl}&spaceId=${this.showActionItem.spaceId}&fileId=${this.showActionItem.id}&type=${this.showActionItem.type.toLowerCase()}`
        },
        async sendLinkMsg(params) {
            console.log(params)
            const {data} = await sendDentryLinkMsg(params)
            console.log(data)
        },
        toggle(index) {
            this.$refs.subscribeCheck[index].toggle();
        },
        async getSubscribeItem() {
            console.log(this.showActionItem)
            const {data} = await getDentryInfo(this.showActionItem.id, this.dingUserInfo.unionid)
            this.showActionItem = data
            this.subscribeResult = []
            if (data.eFolder) {
                this.subscribeResult.push('eFolder')
            }
            if (data.eDelete) {
                this.subscribeResult.push('eDelete')
            }
            if (data.eVersion) {
                this.subscribeResult.push('eVersion')
            }
            if (data.eName) {
                this.subscribeResult.push('eName')
            }
            this.showSubscribe = true
        },
        saveSubscribe() {
            console.log(this.subscribeResult)
            const params = {
                dentryId: this.showActionItem.id,
                unionId: this.dingUserInfo.unionid,
                eName: 0,
                eVersion: 0,
                eDelete: 0,
                eFolder: 0
            }
            this.subscribeResult.forEach(item => {
                params[item] = 1
            })
            console.log(params)
            subscribeEvent(params).then(res => {
                if (res.code === 200) {
                    Toast.success('操作成功!')
                    this.$emit('refresh')
                } else {
                    Toast.fail('订阅失败!')
                }
                this.showSubscribe = false
            })
        }
    }
}
</script>

<style scoped lang="less">
.file-card {
  height: 52px;
  display: flex;
  align-items: center;
  padding: 4px 8px;

  &:hover {
    background-color: #EFEFEF;
  }
}

.file-card-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.file-card-title-desc {
  font-size: 12px;
  color: #8f99a8;
  font-weight: 500;
}

.file-card-title span {
  margin-left: 4px;
  display: -webkit-box;
  overflow: hidden;
  white-space: normal !important;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: 1;
  /* autoprefixer: off*/
  -webkit-box-orient: vertical;
  /* autoprefixer: on*/
  text-align: left;
  max-width: -webkit-fill-available;
}

.file-card-opr {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 3em;
  height: 3em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.el-breadcrumb {
  line-height: 2;
}

.file-icon {
  position: relative;
  width: fit-content;

  .subscribe {
    width: 1.3em;
    position: absolute;
    top: 15px;
    right: -5px;
  }
}

.van-tag--large {
  line-height: 24px;
  margin-left: 8px;
  margin-bottom: 4px;
}

::v-deep .van-field {
  .van-cell__value {
    display: inline-flex;
    justify-content: space-between;
  }
}
.share {
    display: flex;
    flex-direction: column;
    align-items: center;
    .share-btn {
        display: flex;
        width: 55px;
        height: 55px;
        border: 1px solid #EFEFEF;
        background-color: #EFEFEF;
        border-radius: 30px;
        justify-content: center;
        align-items: center;

        .iconfont {
            font-size: 24px;
            color: #606266;
        }
    }
    span {
        font-size: 14px;
    }
}
.share-config {
    width: 100%;
    margin: 12px;
    padding: 4px 12px;
    background-color: #f1f1f1;
    border: 1px solid #EFEFEF;
    border-radius: 8px;
    font-size: 14px;
}
.flex-start {
    display: flex;
    justify-content: flex-start;
}
.flex-end {
    display: flex;
    justify-content: flex-end;
}
.iconfont {
    display: flex;
    align-items: center;
}
.blue-span {
    color: #409eff;
    font-weight: 600;
}
.check-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.validity-desc {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #abb3bf;
    font-size: 12px;
    font-weight: 500;
}
.validity-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: max-content;
}
.van-action-sheet__header {
    border-bottom: 1px solid #EFEFEF;
}
.password-input {
    border: 1px solid #EFEFEF;
    border-radius: 8px;
    padding: 6px 12px;
    background-color: #e4e7ed47;
    min-width: 300px;
    ::v-deep .van-field__control {
        min-width: 280px;
    }
}
</style>
<style lang="less">
.van-popover__content {
    width: 256px;
    padding: 12px;
    background-color: deepskyblue !important;
    color: white;
}
.van-popover__arrow {
    color: deepskyblue !important;
}
.final-url {
    border: 1px solid #EFEFEF;
    border-radius: 8px;
    padding: 12px;
    background-color: #EFEFEF;
    word-break: break-all;
}
.qr-contain {
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.van-cell__value {
    display: flex;
    align-items: center;
}
.a-value {
    color: #409eff;
    display: flex;
    justify-content: flex-end;
}
</style>