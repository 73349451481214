<template>
    <div class="home">
        <van-search
                v-model="keywords"
                placeholder="请输入搜索关键词"
                input-align="center"
                @focus="toSearch"
        />
        <div style="padding: 12px 7%;" class="breadcrumb">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :key="record.title" v-for="record in breadcrumbList" :to="record" :replace="true">{{ record.title }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div style="padding: 12px 7%;" class="mobile-breadcrumb">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :key="record.title" v-for="record in mobileBreadcrumbList" :to="record.name ? record : null" :replace="true">{{ record.title }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <van-pull-refresh v-model="fileRefreshing" @refresh="reLoadFile">
            <van-list
                    v-model="fileLoading"
                    :finished="fileFinished"
                    finished-text=""
                    @load="getFileList"
                    class="content-fill"
            >
                <FileCard
                    v-for="item in fileList"
                    :key="'div'+ item.id"
                    slot="default"
                    @refresh="reLoadFile"
                    :file-info="item"
                />
                <van-empty description="暂无文件" v-if="isListEmpty"/>
            </van-list>
        </van-pull-refresh>
    </div>
</template>

<script>
import Vue from 'vue';
import {
    Button,
    Divider,
    Cell,
    CellGroup,
    List,
    PullRefresh,
    Col,
    Row,
    Icon,
    ActionSheet,
    Search,
    Empty,
} from 'vant';
import FileCard from "@/views/components/FileCard.vue";
import { getFolderBreadcrumb, getUserFileList } from "@/api/manage";
import {formatSize, formatTime, getIconWithExtension, getSendIconWithExtension} from "@/utils/commonFunction";
import * as dd from "dingtalk-jsapi";

Vue.use(Button);
Vue.use(List);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Divider);
Vue.use(PullRefresh);
Vue.use(Col);
Vue.use(Row);
Vue.use(Icon);
Vue.use(ActionSheet);
Vue.use(Search);
Vue.use(Empty);

export default {
    name: 'Home',
    components: { FileCard },
    inject: ['reload'],
    methods: {
        async initCurrentBreadcrumb () {
            this.breadcrumbList = [
                {
                    path: "/home",
                    name: "Home",
                    title: '团队空间'
                }
            ]
            const { data } = await getFolderBreadcrumb(this.spaceId, this.parentId)
            console.log('initCurrentBreadcrumb', data)
            if (data && data.pathIds) {
                for (let i=0; i<data.pathIds.length; i++) {
                    this.breadcrumbList.push({
                        path: "/refresh",
                        name: "Refresh",
                        query: {
                            spaceId: this.spaceId,
                            parentId: i === 0 ? 0 : data.pathIds[i]
                        },
                        title: data.pathNames[i]
                    })
                }
                const length = this.breadcrumbList.length
                if (length >= 3) {
                    this.mobileBreadcrumbList.push(this.breadcrumbList[0])
                    this.mobileBreadcrumbList.push({
                        title: '...'
                    })
                    this.mobileBreadcrumbList.push(this.breadcrumbList[length - 1])
                } else {
                    this.mobileBreadcrumbList = this.breadcrumbList
                }
            }
        },
        toSearch() {
            this.$router.push({
                path: "/search",
                name: "Search",
                query: {
                    spaceId: this.spaceId,
                    parentId: this.parentId
                }
            })
        },
        reLoadFile() {
            this.fileRefreshing = true
            this.getFileList()
        },
        async getFileList() {
            if (!sessionStorage.getItem('dingUserInfo')) {
                return
            }
            console.log(this.dingUserInfo)
            this.fileLoading = false
            this.fileFinished = false
            const {code, data} = await getUserFileList(this.spaceId, this.parentId, this.dingUserInfo.unionid)
            this.fileList = [
                {
                    id: data.parentId,
                    type: 'backToTop',
                    name: '返回上一级',
                    parentId: data.parentId,
                    spaceId: this.spaceId
                }
            ]
            console.log(data)
            if (code === 200) {
                this.fileList = this.fileList.concat(data.list)
            }
            this.fileLoading = true
            this.fileFinished = true
            this.fileRefreshing = false
        }
    },
    computed: {
        isListEmpty () {
            return this.fileList.length === 0 || (this.fileList.length === 1 && this.fileList[0].type === 'backToTop')
        },
        dingUserInfo() {
            return JSON.parse(sessionStorage.getItem('dingUserInfo'))
        },
        formatTime() {
            return (time) => {
                if (time) {
                    return formatTime(time.replace('CST', 'GMT+8'))
                }
                return ''
            }
        },
        formatSize() {
            return (size) => {
                return formatSize(size)
            }
        },
        folderIcon() {
            return (type, extension) => {
                if (type === 'FOLDER') {
                    return '#icon-folder'
                } else if (type === 'backToTop') {
                    return '#icon-folder-top'
                }
                return getIconWithExtension(extension)
            }
        },
        sendIcon() {
            return (type, extension) => {
                if (type === 'FOLDER') {
                    return '@lALPDeREdlJdY6fMyMzI'
                }
                return getSendIconWithExtension(extension)
            }
        },
        currEnv () {
            return dd.env.platform
        }
    },
    mounted() {
        this.spaceId = this.$route.query.spaceId
        this.parentId = this.$route.query.parentId || 0
        this.initCurrentBreadcrumb()
    },
    data() {
        return {
            breadcrumbList: [],
            mobileBreadcrumbList: [],
            spaceId: 0,
            parentId: 0,
            fileLoading: false,
            fileRefreshing: false,
            fileFinished: false,
            fileList: [],
            keywords: ''

        };
    },
}
</script>

<style scoped lang="less">
.content {
  padding: 16px 16px 160px;
}

.content-fill {
  height: calc(100vh - 160px);
}
.mobile-breadcrumb {
    display: none;
}
@media screen and ( max-width: 500px ) {
    .content-fill {
        height: calc(100vh - 140px);
    }
    .breadcrumb {
        display: none;
    }
    .mobile-breadcrumb {
        display: block;
    }
}
.van-pull-refresh {
  overflow: auto;
}

</style>