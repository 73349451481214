import axios from 'axios'
import { Notify } from 'vant';
import { VueAxios } from './axios'
import { baseUrl } from "@/config/publicConfig";

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: baseUrl,
  timeout: 6000000 // 请求超时时间
})

// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    const data = error.response.data
    // 从 localstorage 获取 token
    if (error.response.status === 403) {
      Notify(data.message)
    }
  }
  return Promise.reject(error)
}

// response interceptor
request.interceptors.response.use((response) => {
  return response.data
}, errorHandler)

const installer = {
  vm: {},
  install (Vue) {
    Vue.use(VueAxios, request)
  }
}

export default request

export {
  installer as VueAxios,
  request as axios
}